import React from "react";
import Login from "../components/Login";
import PageWrapper from "../../template/PageWrapper";
import { Route, Switch } from "react-router-dom";
import ForgotPassword from "../components/ForgotPassword";

export const LOCAL_STORAGE_LOGIN_EMAIL = "ishka_login_remember_email";

const AuthApp = (props) => {
    const storageAvailable = isStorageAvailable("localStorage");
    return (
        <PageWrapper {...props}>
            <Switch>
                <Route
                    exact
                    path="/login"
                    component={(props) => <Login {...props} storageAvailable={storageAvailable} />}
                />
                <Route
                    path="/login/forgot"
                    component={(props) => <ForgotPassword {...props} storageAvailable={storageAvailable} />}
                />
            </Switch>
        </PageWrapper>
    );
};

const isStorageAvailable = (type) => {
    let storage;
    try {
        storage = window[type];
        const x = "__storage_test__";
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return (
            e instanceof DOMException &&
            // everything except Firefox
            (e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === "QuotaExceededError" ||
                // Firefox
                e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage &&
            storage.length !== 0
        );
    }
};

export default AuthApp;
