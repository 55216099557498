import { USER_SIGN_IN_FULFILLED, USER_SIGN_OUT_FULFILLED } from "../auth/constants/authConstants";
import toastr from "toastr";
import "toastr/toastr.scss";
import "../../assets/styles/plugins/toastr.css";
import { FLASH_MESSAGE_ADD } from "./flashMessageConstants";

toastr.options = {
    closeButton: true,
    debug: false,
    progressBar: true,
    preventDuplicates: false,
    positionClass: "toast-top-right",
    onclick: null,
    showDuration: "400",
    hideDuration: "1000",
    timeOut: "7000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
};

export const flashMessages = (state = [], action) => {
    switch (action.type) {
        case USER_SIGN_IN_FULFILLED:
            addFlashMessage({ type: "success", message: "Signed in successfully." });
            return state;
        case USER_SIGN_OUT_FULFILLED:
            addFlashMessage({ type: "success", message: "Signed out successfully." });
            return state;
        case FLASH_MESSAGE_ADD:
            addFlashMessage(action.payload);
            return state;
        default: {
            const data = extractMessagesFromAction(action);
            if (data && data.error) {
                /* devise */
                addFlashMessage({ type: "error", message: data.error });
                return state;
            } else if (data && data.flash) {
                addFlashMessages(data.flash);
                return state;
            } else {
                return state;
            }
        }
    }
};

function extractMessagesFromAction(action) {
    if (!action.payload) return [];
    if (action.error && action.payload.response) {
        if (action.payload.response.status !== 404) {
            return action.payload.response.data;
        }
    } else if (action.payload.flash) {
        return action.payload;
    } else if (action.payload.data && action.payload.data.flash) {
        return action.payload.data;
    }
    return [];
}

const addFlashMessages = (messages) => {
    messages.map((message) => {
        addFlashMessage(message);
    });
};

const addFlashMessage = (message) => {
    switch (message.type) {
        case "success":
            toastr.success(message.message);
            break;
        case "info":
            toastr.info(message.message);
            break;
        case "error":
            toastr.error(message.message);
            break;
        case "warn":
            toastr.warning(message.message);
    }
};
