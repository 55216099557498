import {
    SELECT_MTOW_UNIT,
    TYPE_MTOW,
    HYDRATE_MTOW_FULFILLED,
    CHECK_MTOW_VALUE,
    RESET_MTOW,
} from "../constants/mtowConstants";
import { AUTOFILL_CONFIGURATION_FULFILLED } from "../constants/aircraftBaselinesConstants";

const defaultConfiguration = {
    units: [
        { value: 0, label: "kg" },
        { value: 1, label: "lb" },
    ],
    kg: { min: 0, max: 0, cost: 0 },
    lb: { min: 0, max: 0, cost: 0 },
    baseline_kg: 0,
    baseline_lb: 0,
    current: "",
    currentUnit: 0,
    displayGuidance: false,
};

export const mtow = (state = defaultConfiguration, action) => {
    switch (action.type) {
        case SELECT_MTOW_UNIT:
            return {
                ...state,
                currentUnit: action.payload,
                current: action.payload === 0 ? state.baseline_kg : state.baseline_lb,
            };

        case TYPE_MTOW:
            return { ...state, current: action.payload };

        case CHECK_MTOW_VALUE: {
            let currentVal = state.current;
            let guidance = false;
            if (state.currentUnit === 0) {
                if ((state.kg.min && currentVal < state.kg.min) || (state.kg.max && currentVal > state.kg.max)) {
                    guidance = true;
                }
                currentVal = state.kg.min && currentVal < state.kg.min ? state.kg.min : currentVal;
                currentVal = state.kg.max && currentVal > state.kg.max ? state.kg.max : currentVal;
            } else {
                if ((state.lb.min && currentVal < state.lb.min) || (state.lb.max && currentVal > state.lb.max)) {
                    guidance = true;
                }
                currentVal = state.lb.min && currentVal < state.lb.min ? state.lb.min : currentVal;
                currentVal = state.lb.max && currentVal > state.lb.max ? state.lb.max : currentVal;
            }
            return { ...state, current: currentVal, displayGuidance: guidance };
        }

        case HYDRATE_MTOW_FULFILLED: {
            const data = action.payload.data;
            let current = state.current;
            let currentUnit = state.currentUnit;
            if (action.payload.data === null) {
                return state;
            }
            if (current === "") {
                currentUnit = data.weight_unit === "kg" ? 0 : 1;
                current = data.weight_unit === "kg" ? data.kg_baseline : data.lb_baseline;
            } else if (currentUnit === 0 && data.kg_min && data.kg_max) {
                current = current < data.kg_min ? data.kg_min : current;
                current = current > data.kg_max ? data.kg_max : current;
            } else if (currentUnit === 1 && data.lb_min && data.lb_max) {
                current = current < data.lb_min ? data.lb_min : current;
                current = current > data.lb_max ? data.lb_max : current;
            }
            return {
                ...state,
                kg: {
                    ...state.kg,
                    min: data.kg_min,
                    max: data.kg_max,
                    cost: data.kg_cost,
                },
                lb: {
                    ...state.lb,
                    min: data.lb_min,
                    max: data.lb_max,
                    cost: data.lb_cost,
                },
                baseline_kg: data.kg_baseline,
                baseline_lb: data.lb_baseline,
                current: current,
                currentUnit: currentUnit,
            };
        }
        case AUTOFILL_CONFIGURATION_FULFILLED: {
            const unit = state.units.find((unit) => action.payload.data.mtow_unit === unit.label);
            return {
                ...state,
                current: action.payload.data.mtow ? action.payload.data.mtow : state.current,
                currentUnit: unit ? unit.value : state.currentUnit,
            };
        }
        case RESET_MTOW:
            return defaultConfiguration;

        default:
            return state;
    }
};
