export const HYDRATE_AIRCRAFT_MARKET_VALUES = "HYDRATE_AIRCRAFT_MARKET_VALUES";
export const HYDRATE_AIRCRAFT_MARKET_VALUES_PENDING = "HYDRATE_AIRCRAFT_MARKET_VALUES_PENDING";
export const HYDRATE_AIRCRAFT_MARKET_VALUES_FULFILLED = "HYDRATE_AIRCRAFT_MARKET_VALUES_FULFILLED";
export const HYDRATE_AIRCRAFT_MARKET_VALUES_REJECTED = "HYDRATE_AIRCRAFT_MARKET_VALUES_REJECTED";

export const RESET_AIRCRAFT_MARKET_VALUES = "RESET_AIRCRAFT_MARKET_VALUES";

export const SELECT_AIRCRAFT_MARKET_VALUES_VINTAGE = "SELECT_AIRCRAFT_MARKET_VALUES_VINTAGE";

export const VALUATION_ENTER_CUSTOMER_NOTES = "VALUATION_ENTER_CUSTOMER_NOTES";

export const HIDE_VALUATION_PARAMS = "HIDE_VALUATION_PARAMS";
