import {
    RETRIEVE_PURCHASE_PRICES_PENDING,
    RETRIEVE_PURCHASE_PRICES_FULFILLED,
    RETRIEVE_PURCHASE_PRICES_REJECTED,
    RETRIEVE_AIRCRAFT_REGISTRATION_FULFILLED,
    RESET_SELECTED_AIRCRAFT_REGISTRATION,
} from "../constants/purchasePricesConstants";

const defaultConfiguration = {
    purchasePrices: [],
    valuations: [],
    fetching: false,
    aircraftRegistration: null,
    aircraftVariantName: "",
};

export const purchasePrices = (state = defaultConfiguration, action) => {
    switch (action.type) {
        case RETRIEVE_PURCHASE_PRICES_PENDING:
            return {
                ...state,
                fetching: true,
            };

        case RETRIEVE_PURCHASE_PRICES_FULFILLED:
            return {
                ...state,
                fetching: false,
                purchasePrices: action.payload.data.purchase_prices,
                valuations: action.payload.data.valuations,
                aircraftVariantName: action.payload.data.aircraft_variant_name,
            };

        case RETRIEVE_PURCHASE_PRICES_REJECTED:
            return {
                ...state,
                fetching: false,
            };
        case RETRIEVE_AIRCRAFT_REGISTRATION_FULFILLED:
            return {
                ...state,
                aircraftRegistration: action.payload.data,
            };
        case RESET_SELECTED_AIRCRAFT_REGISTRATION:
            return {
                ...state,
                aircraftRegistration: null,
            };
        default:
            return state;
    }
};
