import {
    FETCH_FUTURE_VALUES_PENDING,
    FETCH_FUTURE_VALUES_FULFILLED,
    FETCH_FUTURE_VALUES_REJECTED,
    RESET_FUTURE_VALUES,
} from "../constants/aircraftFutureValuesConstants";

const defaultConfiguration = {
    timestamp: null,
    forecastHorizon: 0,
    inflationRate: 0,
    valuationType: "",
    mtowType: "",
    aircraftType: "",
    aircraftVariant: "",
    yearOfBuilt: 0,
    engineSubSeries: "",
    specAdjusters: [],
    future_values: [],
};

export const aircraftFutureValues = (state = defaultConfiguration, action) => {
    switch (action.type) {
        case FETCH_FUTURE_VALUES_PENDING:
            return state;

        case FETCH_FUTURE_VALUES_FULFILLED: {
            return action.payload.data;
            // let modifiers = [];
            // if(action.payload.data.spec_adjusters != null) { modifiers = [...action.payload.data.spec_adjusters] }
            // return {
            //   ...state,
            //   timestamp: action.payload.data.timestamp,
            //   forecastHorizon: action.payload.data.forecast_horizon,
            //   inflationRate: action.payload.data.inflation_rate,
            //   valuationType: action.payload.data.valuation_type,
            //   mtowType: action.payload.data.mtow_type,
            //   aircraftType: action.payload.data.aircraft_type,
            //   aircraftVariant: action.payload.data.aircraft_variant,
            //   yearOfBuilt: action.payload.data.year_of_built,
            //   engineSubSeries: action.payload.data.engine_sub_series,
            //   specAdjusters: modifiers,
            //   marketValues: action.payload.data.market_values,
            //   baseValues: [...action.payload.data.base_values]
            // };
        }

        case FETCH_FUTURE_VALUES_REJECTED:
            return [];

        case RESET_FUTURE_VALUES:
            return defaultConfiguration;

        default:
            return state;
    }
};
