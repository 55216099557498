import React from "react";
import PageWrapper from "../../shared/template/PageWrapper";
import HomeContainer from "./containers/HomeContainer";

const HomeApp = (props) => (
    <PageWrapper
        title="Ishka Transaction Economics"
        description="A new era of innovation in aircraft values and transaction data from Ishka."
        {...props}
    >
        <HomeContainer />
    </PageWrapper>
);

export default HomeApp;
