import {
    HYDRATE_ENGINE_MODELS_PENDING,
    HYDRATE_ENGINE_MODELS_FULFILLED,
    HYDRATE_ENGINE_MODELS_REJECTED,
    SELECT_ENGINE_MODEL,
    RESET_ENGINE_MODELS,
} from "../constants/engineModelsConstants";

import { AUTOFILL_CONFIGURATION_FULFILLED } from "../constants/aircraftBaselinesConstants";

const defaultConfiguration = {
    fetching: false,
    selected: -1,
    list: [],
};

export const engineModels = (state = defaultConfiguration, action) => {
    switch (action.type) {
        case HYDRATE_ENGINE_MODELS_PENDING:
            return { ...state, fetching: true };

        case HYDRATE_ENGINE_MODELS_FULFILLED:
            return {
                ...state,
                fetching: false,
                list: action.payload.data
                    .map((em) => {
                        return { value: em.id, label: em.code };
                    })
                    .sort((x, y) => {
                        return x.label < y.label ? -1 : 1;
                    }),
            };

        case HYDRATE_ENGINE_MODELS_REJECTED:
            return { ...state, fetching: false };

        case SELECT_ENGINE_MODEL:
            return { ...state, selected: action.payload };

        case AUTOFILL_CONFIGURATION_FULFILLED: {
            return {
                ...state,
                selected: action.payload.data.engine_model.selected,
                list: action.payload.data.engine_model.list
                    .map((em) => {
                        return { value: em.id, label: em.code };
                    })
                    .sort((x, y) => {
                        return x.label < y.label ? -1 : 1;
                    }),
            };
        }

        case RESET_ENGINE_MODELS:
            return defaultConfiguration;

        default:
            return state;
    }
};
