import { generateLabelForCriteria } from "../containers/transactionGraphUtils";

export const AXIS_TYPES = [
    { value: 1, label: "Year of Transaction", id: "transaction_year", format: "d" },
    { value: 2, label: "Sale or Purchase Price ($)", id: "plot_price", format: ".2s" },
    { value: 3, label: "Lease Rate ($)", id: "lease_rate", format: ".2s" },
    { value: 4, label: "Lease Rate Factor (%)", id: "lease_rate_factor", format: ".2%" },
    { value: 5, label: "Aircraft age (y)", id: "aircraft_age", format: "d" },
    { value: 6, label: "Maintenance Reserve Balance ($)", id: "maintenance_reserves", format: ".2s" },
];

const CRITERIA = [
    "transaction_year",
    "aircraft_age",
    "lessee_credit_quality",
    "lessee_business_model",
    "lease_term",
    "security_deposit_months",
];

export const CRITERIA_TYPES = CRITERIA.map((criteria) => {
    return { value: criteria, label: generateLabelForCriteria(criteria) };
});
