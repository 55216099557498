import React from "react";
import { connect } from "react-redux";
import "../assets/js/inspinia";
import { signOutUser } from "../shared/auth/actions/authActionCreators";
import SubMenuItem from "../shared/template/SubMenuItem";
import MenuItem from "../shared/template/MenuItem";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faPlane } from "@fortawesome/free-solid-svg-icons/faPlane";
import { faUserTie } from "@fortawesome/free-solid-svg-icons/faUserTie";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { faUsersCog } from "@fortawesome/free-solid-svg-icons/faUsersCog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IshkaLogo from "../shared/template/IshkaLogo";
import {
    AMPL_GLOSSARY_BUTTON_CLICKED,
    AMPL_HOME_BUTTON_CLICKED,
    AMPL_METHODOLOGY_BUTTON_CLICKED,
    AMPL_PORTFOLIO_BUTTON_CLICKED,
    AMPL_TRANSACTION_BUTTON_CLICKED,
    AMPL_VALUATION_BUTTON_CLICKED,
} from "../shared/amplitudeConstants";
import { hasRole, sendToAmplitude } from "../shared/ishkaUtilities";
import { faRandom } from "@fortawesome/free-solid-svg-icons/faRandom";
import { faTools } from "@fortawesome/free-solid-svg-icons/faTools";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons/faAlignLeft";

class NavBar extends React.Component {
    logout() {
        this.props.signOutUser().then(() => {
            this.props.history.push("/login");
        });
    }

    buttonClick(event) {
        sendToAmplitude(event);
    }

    renderLoggedInLinks() {
        const pathName = this.props.location.pathname;
        return (
            <ul className="nav metismenu" id="side-menu">
                <IshkaLogo />

                <MenuItem
                    onClickEvent={() => this.buttonClick(AMPL_HOME_BUTTON_CLICKED)}
                    link="/"
                    label="Home"
                    icon={faHome}
                    pathName={pathName}
                />
                <MenuItem
                    onClickEvent={() => this.buttonClick(AMPL_VALUATION_BUTTON_CLICKED)}
                    link="/valuations"
                    matchingLinks={["/valuation", "/portfolios"]}
                    label="Valuations"
                    icon={faPlane}
                    pathName={pathName}
                >
                    {this.renderValuationMenu(pathName, true)}
                </MenuItem>
                <MenuItem
                    onClickEvent={() => this.buttonClick(AMPL_TRANSACTION_BUTTON_CLICKED)}
                    link="/transactions"
                    label="Transactions"
                    icon={faRandom}
                    pathName={pathName}
                />

                <li>
                    <hr />
                </li>
                <MenuItem
                    link="/methodology"
                    label="Methodology"
                    icon={faTools}
                    pathName={pathName}
                    onClickEvent={() => this.buttonClick(AMPL_METHODOLOGY_BUTTON_CLICKED)}
                />
                <MenuItem
                    link="/glossary"
                    label="Glossary"
                    icon={faAlignLeft}
                    pathName={pathName}
                    onClickEvent={() => this.buttonClick(AMPL_GLOSSARY_BUTTON_CLICKED)}
                />
                <MenuItem link="/meet-the-experts" label="Meet the Team" icon={faUserTie} pathName={pathName} />

                {this.renderAdminMenu(pathName)}
                <li>
                    <hr />
                </li>
                <li>
                    <a href="#" onClick={() => this.logout()}>
                        <FontAwesomeIcon icon={faSignOutAlt} />
                        &nbsp;&nbsp;<span className="nav-label">Logout</span>
                    </a>
                </li>
            </ul>
        );
    }

    renderValuationMenu(pathName, active) {
        return (
            <ul className={"nav nav-second-level collapse" + (active ? " in" : "")}>
                <SubMenuItem
                    pathName={pathName}
                    name="Individual Aircraft"
                    onClickEvent={() => this.buttonClick(AMPL_VALUATION_BUTTON_CLICKED)}
                    link="/valuations"
                />
                <SubMenuItem
                    pathName={pathName}
                    matchingLinks={["/portfolios"]}
                    name="Portfolio"
                    onClickEvent={() => this.buttonClick(AMPL_PORTFOLIO_BUTTON_CLICKED)}
                    link="/portfolios"
                />
            </ul>
        );
    }

    renderAdminMenu(pathName) {
        if (hasRole(this.props.user, "admin") || hasRole(this.props.user, "data_extractor")) {
            return (
                <React.Fragment>
                    <li>
                        <hr />
                    </li>
                    <MenuItem url="/admin" label="Administration" icon={faUsersCog} pathName={pathName} />
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <nav className="navbar-default navbar-static-side" role="navigation">
                <div className="sidebar-collapse">{this.renderLoggedInLinks()}</div>
            </nav>
        );
    }
}

const mapStateToProps = (state) => {
    if (state.aircraftBaselines !== undefined) {
        return {
            active: {
                futureValues: state.aircraftBaselines.list.length > 0 && state.aircraftBaselines.searchDone,
                purchasePrices: state.aircraftBaselines.list.length > 0 && state.aircraftBaselines.searchDone,
                aggregatedReport: state.aircraftBaselines.list.length > 0 && state.aircraftBaselines.searchDone,
            },
            user: state.userReducer.user,
        };
    } else {
        return {
            user: state.userReducer.user,
        };
    }
};

export default connect(mapStateToProps, { signOutUser })(NavBar);
