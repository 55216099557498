import {
    HYDRATE_TRANSACTIONS_FILTER_PENDING,
    HYDRATE_TRANSACTIONS_FILTER_FULFILLED,
    HYDRATE_TRANSACTIONS_FILTER_REJECTED,
    TRANSACTION_FILTER_CHOOSE_AIRCRAFT_VARIANT,
    TRANSACTION_FILTER_DATE_START_CHANGE,
    TRANSACTION_FILTER_DATE_END_CHANGE,
    SELECT_CHARTING_PARAMTER,
    SELECT_CHARTING_X,
    SELECT_CHARTING_Y,
    SELECT_GRAPH_TO_MAGNIFY,
    TRANSACTION_FILTER_VINTAGE_RANGE_FROM_CHANGE,
    TRANSACTION_FILTER_VINTAGE_RANGE_TO_CHANGE,
    TRANSACTION_FILTER_RETRIEVE_VINTAGES_FULFILLED,
    SELECT_CHARTING_CRITERIA,
} from "../constants/transactionsFilterConstants";

import {
    RESET_TRANSACTIONS_DATAPOINTS,
    FETCH_TRANSACTIONS_DATAPOINTS_FULFILLED,
    FETCH_TRANSACTIONS_DATAPOINTS_PENDING,
    FETCH_TRANSACTIONS_DATAPOINTS_REJECTED,
} from "../constants/fetchDataConstants";
import { AXIS_TYPES } from "../constants/commonConstants";

const resetConfiguration = {
    fetching: false,
    selectedAircraftVariant: -1,
    dateMin: new Date().getFullYear() - 30,
    dateMax: new Date().getFullYear(),
    xAxis: null,
    yAxis: null,
    criteria: null,
    vintages: [],
    vintageRangeFrom: "",
    vintageRangeTo: "",
    magnified: null,
    axisList: AXIS_TYPES,
};

const defaultConfiguration = {
    aircraftVariants: [],
    ...resetConfiguration,
};

const computeDateMax = (state, dateMin) => {
    if (dateMin === "" || dateMin === "NaN-NaN-NaN") {
        return state.dateMax;
    }
    return state.dateMax < dateMin ? dateMin : state.dateMax;
};

const computeDateMin = (state, dateMax) => {
    return state.dateMin > dateMax ? dateMax : state.dateMin;
};

export const transactionsFilter = (state = defaultConfiguration, action) => {
    switch (action.type) {
        case FETCH_TRANSACTIONS_DATAPOINTS_PENDING:
        case HYDRATE_TRANSACTIONS_FILTER_PENDING:
            return {
                ...state,
                fetching: true,
            };

        case HYDRATE_TRANSACTIONS_FILTER_FULFILLED:
            return {
                ...state,
                fetching: false,
                aircraftVariants: action.payload.data.map((d) => {
                    return { value: d.id, label: d.name };
                }),
            };

        case FETCH_TRANSACTIONS_DATAPOINTS_FULFILLED:
        case FETCH_TRANSACTIONS_DATAPOINTS_REJECTED:
        case HYDRATE_TRANSACTIONS_FILTER_REJECTED:
            return {
                ...state,
                fetching: false,
            };

        case SELECT_CHARTING_X:
            return { ...state, xAxis: action.payload };

        case SELECT_CHARTING_Y:
            return { ...state, yAxis: action.payload };

        case SELECT_CHARTING_CRITERIA:
            return { ...state, criteria: action.payload };

        case TRANSACTION_FILTER_VINTAGE_RANGE_FROM_CHANGE:
            return { ...state, vintageRangeFrom: action.payload };

        case TRANSACTION_FILTER_VINTAGE_RANGE_TO_CHANGE:
            return { ...state, vintageRangeTo: action.payload };

        case SELECT_GRAPH_TO_MAGNIFY:
            return { ...state, magnified: action.payload };

        case TRANSACTION_FILTER_CHOOSE_AIRCRAFT_VARIANT:
            return {
                ...state,
                selectedAircraftVariant: action.payload,
                vintage: [],
            };

        case TRANSACTION_FILTER_DATE_START_CHANGE:
            return {
                ...state,
                dateMin: action.payload,
                dateMax: computeDateMax(state, action.payload),
            };

        case TRANSACTION_FILTER_DATE_END_CHANGE:
            return {
                ...state,
                dateMax: action.payload,
                dateMin: computeDateMin(state, action.payload),
            };

        case SELECT_CHARTING_PARAMTER:
            return {
                ...state,
                chartingParams: state.chartingParams.map((cp) => {
                    if (action.payload.includes(cp.value)) {
                        return { ...cp, selected: true };
                    } else {
                        return { ...cp, selected: false };
                    }
                }),
            };

        case TRANSACTION_FILTER_RETRIEVE_VINTAGES_FULFILLED: {
            return {
                ...state,
                vintages: action.payload.data.map((v) => {
                    return { value: v, label: v };
                }),
            };
        }

        case RESET_TRANSACTIONS_DATAPOINTS:
            return { ...state, ...resetConfiguration };

        default:
            return state;
    }
};
