const defaultColor = "#8884d8";

export function generateLabelForCriteria(condition) {
    if (condition === "transaction_year") {
        return "Transaction Year";
    } else if (condition === "aircraft_age") {
        return "Aircraft Age";
    } else if (condition === "lessee_credit_quality") {
        return "Lessee Credit Quality at Time of Transaction";
    } else if (condition === "lessee_business_model") {
        return "Lessee Business Model";
    } else if (condition === "lease_term") {
        return "Lease Term (in months)";
    } else if (condition === "security_deposit_months") {
        return "Security Deposit (in months)";
    } else {
        return "";
    }
}

export function generateDataSetByCriteria(data, name, x, y, condition) {
    if (condition) {
        if (condition === "transaction_year") {
            return generateDataSetColoredByTransactionYear(data, x, y);
        } else if (condition === "aircraft_age") {
            return generateDataSetColoredByAircraftAge(data, x, y);
        } else if (condition === "lessee_credit_quality") {
            return generateDataSetColoredByLesseeCreditQuality(data, x, y);
        } else if (condition === "lessee_business_model") {
            return generateDataSetColoredByLesseeBusinessModel(data, x, y);
        } else if (condition === "lease_term") {
            return generateDataSetColoredByLeaseTerm(data, x, y);
        } else if (condition === "security_deposit_months") {
            return generateDataSetColoredBySecurityDeposit(data, x, y);
        }
    }
    return [generateDataSetColoredByCondition(data, name, x, y, defaultColor, () => true)];
}

function generateDataSetColoredByCondition(data, name, x, y, color, condition) {
    return {
        name,
        x,
        y,
        color,
        data: data.filter((d) => condition(d)),
    };
}

const CURRENT_YEAR = new Date().getFullYear();
const TRANSACTION_YEARS = [
    {
        label: `Before ${CURRENT_YEAR - 16}`,
        condition: (d) => d.transaction_year <= CURRENT_YEAR - 16,
        color: "#4A8DDC",
    },
    {
        label: `${CURRENT_YEAR - 15}-${CURRENT_YEAR - 12}`,
        condition: (d) => d.transaction_year >= CURRENT_YEAR - 15 && d.transaction_year <= CURRENT_YEAR - 12,
        color: "#4C5D8A",
    },
    {
        label: `${CURRENT_YEAR - 11}-${CURRENT_YEAR - 8}`,
        condition: (d) => d.transaction_year >= CURRENT_YEAR - 11 && d.transaction_year <= CURRENT_YEAR - 8,
        color: "#F3C911",
    },
    {
        label: `${CURRENT_YEAR - 7}-${CURRENT_YEAR - 4}`,
        condition: (d) => d.transaction_year >= CURRENT_YEAR - 7 && d.transaction_year <= CURRENT_YEAR - 4,
        color: "#DC5B57",
    },
    {
        label: `${CURRENT_YEAR - 3}-${CURRENT_YEAR}`,
        condition: (d) => d.transaction_year >= CURRENT_YEAR - 3 && d.transaction_year <= CURRENT_YEAR,
        color: "#33AE81",
    },
];

function generateDataSetColoredByTransactionYear(data, x, y) {
    return TRANSACTION_YEARS.map((ty) =>
        generateDataSetColoredByCondition(data, ty.label, x, y, ty.color, ty.condition)
    ).reverse();
}

const AIRCRAFT_AGES = [
    { label: "0-3", condition: (d) => d.aircraft_age <= 3, color: "#4A8DDC" },
    { label: "4-7", condition: (d) => d.aircraft_age >= 4 && d.aircraft_age <= 7, color: "#F3C911" },
    { label: "8-11", condition: (d) => d.aircraft_age >= 8 && d.aircraft_age <= 11, color: "#D46F6C" },
    { label: "12-15", condition: (d) => d.aircraft_age >= 12 && d.aircraft_age <= 15, color: "#9285D2" },
    { label: "Over 15", condition: (d) => d.aircraft_age > 15, color: "#4C5D8A" },
];

function generateDataSetColoredByAircraftAge(data, x, y) {
    return AIRCRAFT_AGES.map((aa) =>
        generateDataSetColoredByCondition(data, aa.label, x, y, aa.color, aa.condition)
    ).reverse();
}

const LESSEE_CREDIT_QUALITY = [
    { label: "Good", value: "GOOD", color: "#0EB194" },
    { label: "Average", value: "AVERAGE", color: "#4A8DDC" },
    { label: "Weak", value: "WEAK", color: "#F47853" },
    { label: "Bankrupt", value: "BANKRUPT", color: "#A66D47" },
    { label: "N/A", value: null, color: defaultColor },
];

function generateDataSetColoredByLesseeCreditQuality(data, x, y) {
    return LESSEE_CREDIT_QUALITY.map((lcq) =>
        generateDataSetColoredByCondition(
            data,
            lcq.label,
            x,
            y,
            lcq.color,
            (d) => d.lessee_quality_credit_today === lcq.value
        )
    ).reverse();
}

const BUSINESS_MODELS = [
    { label: "Charter", value: "CHARTER", color: "#4C5D8A" },
    { label: "Full-Service", value: "FULL-SERVICE CARRIER", color: "#4A8DDC" },
    { label: "Hybrid", value: "HYBRID FULL-SERVICE CARRIER", color: "#E37C79" },
    { label: "Leisure", value: "LEISURE CARRIER", color: "#9A64A0" },
    { label: "Low-Cost", value: "LOW-COST CARRIER", color: "#F3C911" },
    { label: "Regional", value: "REGIONAL", color: "#9285D2" },
    { label: "Ultra-Low-Cost", value: "ULTRA-LOW-COST CARRIER", color: "#33AE81" },
];

function generateDataSetColoredByLesseeBusinessModel(data, x, y) {
    const dataSets = BUSINESS_MODELS.map((bm) =>
        generateDataSetColoredByCondition(data, bm.label, x, y, bm.color, (d) => d.lessee_business_model === bm.value)
    );
    const values = BUSINESS_MODELS.map((bm) => bm.value);
    dataSets.push(
        generateDataSetColoredByCondition(
            data,
            "Others",
            x,
            y,
            defaultColor,
            (d) => values.find((v) => v === d.lessee_business_model) === undefined
        )
    );
    return dataSets.reverse();
}

const LEASE_TERMS = [
    { label: "Less than 36", condition: (d) => d.lease_term < 36, color: "#4A8DDC" },
    { label: "36-60", condition: (d) => d.lease_term >= 36 && d.lease_term <= 60, color: "#4C5D8A" },
    { label: "60-84", condition: (d) => d.lease_term >= 60 && d.lease_term <= 84, color: "#9285D2" },
    { label: "84-120", condition: (d) => d.lease_term >= 84 && d.lease_term <= 120, color: "#F3C911" },
    { label: "120-144", condition: (d) => d.lease_term >= 120 && d.lease_term <= 144, color: "#E37C79" },
    { label: "Over 144", condition: (d) => d.lease_term > 144, color: "#33AE81" },
];

function generateDataSetColoredByLeaseTerm(data, x, y) {
    return LEASE_TERMS.map((lt) =>
        generateDataSetColoredByCondition(data, lt.label, x, y, lt.color, lt.condition)
    ).reverse();
}

const SECURITY_DEPOSITS = [
    { label: "0", condition: (d) => parseSecurityDepositMonths(d.security_deposit_months) === 0, color: "#4A8DDC" },
    { label: "1", condition: (d) => parseSecurityDepositMonths(d.security_deposit_months) === 1, color: "#4C5D8A" },
    { label: "2", condition: (d) => parseSecurityDepositMonths(d.security_deposit_months) === 2, color: "#F3C911" },
    { label: "3", condition: (d) => parseSecurityDepositMonths(d.security_deposit_months) === 3, color: "#D46F6C" },
    {
        label: "N/A",
        condition: (d) => parseSecurityDepositMonths(d.security_deposit_months) === null,
        color: defaultColor,
    },
];

function parseSecurityDepositMonths(value) {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? null : Math.round(parsed);
}

function generateDataSetColoredBySecurityDeposit(data, x, y) {
    return SECURITY_DEPOSITS.map((sd) =>
        generateDataSetColoredByCondition(data, sd.label, x, y, sd.color, sd.condition)
    ).reverse();
}
