export const EV_LEASE_START_SELECT = "EV_LEASE_START_SELECT";
export const EV_LEASE_END_SELECT = "EV_LEASE_END_SELECT";
export const EV_LEASE_RATE_SELECT = "EV_LEASE_RATE_SELECT";
export const EV_PAYMENT_FREQUENCY_SELECT = "EV_PAYMENT_FREQUENCY_SELECT";
export const EV_PAYMENT_TYPE_SELECT = "EV_PAYMENT_TYPE_SELECT";
export const EV_DISCOUNT_RATE_CREDIT_RISK_SELECT = "EV_DISCOUNT_RATE_CREDIT_RISK_SELECT";
export const EV_DISCOUNT_RATE_CREDIT_RISK_CUSTOM_VALUE = "EV_DISCOUNT_RATE_CREDIT_RISK_CUSTOM_VALUE";
export const EV_DISCOUNT_RATE_ASSET_RISK_SELECT = "EV_DISCOUNT_RATE_ASSET_RISK_SELECT";
export const EV_DISCOUNT_RATE_ASSET_RISK_CUSTOM_VALUE = "EV_DISCOUNT_RATE_ASSET_RISK_CUSTOM_VALUE";
export const EV_RETURN_CONDITION_SELECT = "EV_RETURN_CONDITION_SELECT";
export const EV_PRESENT_VALUE_DATE_SELECT = "EV_PRESENT_VALUE_DATE_SELECT";
export const EV_RESIDUAL_VALUE = "EV_RESIDUAL_VALUE";
export const EV_PARAMS_RESET = "EV_PARAMS_RESET";
export const EV_FETCH_RV = "EV_FETCH_RV";
export const EV_FETCH_RV_PENDING = "EV_FETCH_RV_PENDING";
export const EV_FETCH_RV_REJECTED = "EV_FETCH_RV_REJECTED";
export const EV_FETCH_RV_FULFILLED = "EV_FETCH_RV_FULFILLED";
export const EV_LESSEE_RISK_RETRIEVE = "EV_LESSEE_RISK_RETRIEVE";
export const EV_LESSEE_RISK_RETRIEVE_FULFILLED = "EV_LESSEE_RISK_RETRIEVE_FULFILLED";
export const EV_ASSET_RISK_RETRIEVE = "EV_ASSET_RISK_RETRIEVE";
export const EV_ASSET_RISK_RETRIEVE_FULFILLED = "EV_ASSET_RISK_RETRIEVE_FULFILLED";
