import {
    HYDRATE_AIRCRAFT_MODIFIERS_FULFILLED,
    SELECT_AIRCRAFT_MODIFIERS,
    RESET_AIRCRAFT_MODIFIERS,
} from "../constants/aircraftModifiersConstants";
import { AUTOFILL_CONFIGURATION_FULFILLED } from "../constants/aircraftBaselinesConstants";

const defaultConfiguration = [];

export const aircraftModifiers = (state = defaultConfiguration, action) => {
    switch (action.type) {
        case HYDRATE_AIRCRAFT_MODIFIERS_FULFILLED:
            return mapModifiers(action.payload.data);
        case SELECT_AIRCRAFT_MODIFIERS:
            return state.map((m) => {
                if (action.payload.includes(m.id)) {
                    return { ...m, selected: true };
                } else {
                    return { ...m, selected: false };
                }
            });
        case AUTOFILL_CONFIGURATION_FULFILLED:
            if (action.payload.data.modifiers) {
                return mapModifiers(action.payload.data.modifiers);
            } else {
                return state;
            }
        case RESET_AIRCRAFT_MODIFIERS:
            return defaultConfiguration;

        default:
            return state;
    }
};

function mapModifiers(modifiers) {
    return modifiers.map((modifier) => {
        return {
            ...modifier,
            value: modifier.id,
            label: modifier.name,
        };
    });
}
