import {
    HYDRATE_AIRCRAFT_MARKET_VALUES_PENDING,
    HYDRATE_AIRCRAFT_MARKET_VALUES_FULFILLED,
    HYDRATE_AIRCRAFT_MARKET_VALUES_REJECTED,
    RESET_AIRCRAFT_MARKET_VALUES,
    SELECT_AIRCRAFT_MARKET_VALUES_VINTAGE,
    VALUATION_ENTER_CUSTOMER_NOTES,
    HIDE_VALUATION_PARAMS,
} from "../constants/aircraftMarketValuesConstants";
import {
    FETCH_FUTURE_VALUES_FULFILLED,
    FETCH_FUTURE_VALUES_PENDING,
    FETCH_FUTURE_VALUES_REJECTED,
} from "../constants/aircraftFutureValuesConstants";
import { AUTOFILL_CONFIGURATION_FULFILLED } from "../constants/aircraftBaselinesConstants";

const defaultEncumberedParams = {
    aircraftAgeStartOfLease: 0,
    transactionDate: null,
    monthlyLeaseRate: 0,
    leaseLength: 120,
    discountRate: 0,
    creditWorthinessOfLessee: "A",
};

const defaultConfiguration = {
    list: [],
    currentVintage: -1,
    currentMsn: "",
    vintages: [],
    showEmcumberedValues: false,
    showEncumberedParams: false,
    encumberedParams: defaultEncumberedParams,
    searchPanelCollapsed: false,
    noResultsMessage: null,
    errorMessage: null,
    currentCustomerNotes: "",
};

const resetConfiguration = {
    list: [],
    currentVintage: -1,
    vintages: [],
    searchPanelCollapsed: false,
    noResultsMessage: null,
    errorMessage: null,
    currentCustomerNotes: "",
};

export const aircraftMarketValues = (state = defaultConfiguration, action) => {
    switch (action.type) {
        case HYDRATE_AIRCRAFT_MARKET_VALUES_PENDING:
            return { ...state, ...resetConfiguration };

        case HYDRATE_AIRCRAFT_MARKET_VALUES_FULFILLED: {
            let all_vintages = [...new Set(action.payload.data.map((d) => d.vintage))].sort();

            return {
                ...state,
                list: action.payload.data,
                currentVintage: all_vintages[0],
                vintages: all_vintages,
            };
        }

        case VALUATION_ENTER_CUSTOMER_NOTES:
            return {
                ...state,
                currentCustomerNotes: action.payload,
            };

        case HIDE_VALUATION_PARAMS:
            return {
                ...state,
                searchPanelCollapsed: action.payload,
            };
        case FETCH_FUTURE_VALUES_PENDING:
            return {
                ...state,
                noResultsMessage: null,
                errorMessage: null,
            };
        case FETCH_FUTURE_VALUES_FULFILLED: {
            let noFutureValues =
                action.payload.data.future_values === undefined || action.payload.data.future_values.length === 0;
            let mv = action.payload.data.market_values;
            let noMV = mv === undefined || mv.cmv_half_life === "-";

            if (noFutureValues && noMV) {
                return {
                    ...state,
                    noResultsMessage: "No data to show for that selection at the moment",
                };
            }
            return {
                ...state,
                searchPanelCollapsed: true,
            };
        }
        case FETCH_FUTURE_VALUES_REJECTED:
            return {
                ...state,
                errorMessage: action.payload.response.data.flash && action.payload.response.data.flash[0].message,
            };

        case HYDRATE_AIRCRAFT_MARKET_VALUES_REJECTED:
            return state;

        case SELECT_AIRCRAFT_MARKET_VALUES_VINTAGE:
            return { ...state, currentVintage: action.payload };

        case RESET_AIRCRAFT_MARKET_VALUES:
            return defaultConfiguration;

        case AUTOFILL_CONFIGURATION_FULFILLED:
            return {
                ...state,
                currentCustomerNotes: action.payload.data.notes,
            };
        default:
            return state;
    }
};
