import {
    AIRCRAFT_PORTFOLIO_STATS_FULFILLED,
    AIRCRAFT_PORTFOLIO_STATS_PENDING,
    AIRCRAFT_PORTFOLIO_VALUES_FULFILLED,
    AIRCRAFT_PORTFOLIO_VALUES_PENDING,
} from "../constants/aircraftPortfoliosConstants";
import {
    AIRCRAFT_PORTFOLIO_RETRIEVE_FULFILLED,
    AIRCRAFT_PORTFOLIO_RETRIEVE_PENDING,
    AIRCRAFT_PORTFOLIO_RETRIEVE_REJECTED,
    AIRCRAFT_PORTFOLIO_UPDATE_FULFILLED,
} from "../../../shared/portfolios/constants/aircraftPortfoliosConstants";

export const aircraftPortfolios = (state = {}, action) => {
    switch (action.type) {
        case AIRCRAFT_PORTFOLIO_STATS_PENDING:
            return {
                ...state,
                stats: undefined,
            };
        case AIRCRAFT_PORTFOLIO_STATS_FULFILLED:
            return {
                ...state,
                stats: action.payload.data,
            };
        case AIRCRAFT_PORTFOLIO_UPDATE_FULFILLED:
        case AIRCRAFT_PORTFOLIO_RETRIEVE_FULFILLED:
            return {
                ...state,
                record: action.payload.data,
            };
        case AIRCRAFT_PORTFOLIO_RETRIEVE_REJECTED:
            return {
                ...state,
                record: null,
            };
        case AIRCRAFT_PORTFOLIO_RETRIEVE_PENDING:
            return {
                ...state,
                record: undefined,
            };
        case AIRCRAFT_PORTFOLIO_VALUES_PENDING:
            return {
                ...state,
                values: undefined,
            };
        case AIRCRAFT_PORTFOLIO_VALUES_FULFILLED:
            return {
                ...state,
                values: action.payload.data,
            };
        default:
            return state;
    }
};
