import React from "react";
import { Link } from "react-router-dom";
import {
    AMPL_BUILD_MANAGE_PORTFOLIO_CLICKED,
    AMPL_SEARCH_INDIVIDUAL_BUTTON_CLICKED,
    AMPL_SEARCH_TRANSACTION_BUTTON_CLICKED,
} from "../../../shared/amplitudeConstants";
import { sendToAmplitude } from "../../../shared/ishkaUtilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools } from "@fortawesome/free-solid-svg-icons/faTools";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons/faAlignLeft";
import { faUserTie } from "@fortawesome/free-solid-svg-icons/faUserTie";
import { faPlane } from "@fortawesome/free-solid-svg-icons/faPlane";
import { faRandom } from "@fortawesome/free-solid-svg-icons/faRandom";

class HomeContainer extends React.Component {
    lead() {
        return (
            <div className="card">
                <div className="row align-items-center row-eq-height m-2">
                    <div className="col-lg-6 lead pl-4 pr-4">
                        “We are delighted to introduce Transaction Economics — our progressive new aircraft valuation
                        and transactions platform that fulfills the rigor, consistency and transparency required by
                        today&apos;s market.”
                    </div>
                    <div className="col-lg-6">
                        <div className="row align-items-center r">
                            <div className="col-auto">
                                <img
                                    src="/experts/Eddy.jpg"
                                    alt="Eddy Pieniazek, Head of Advisory, Ishka"
                                    className="rounded-circle"
                                    width="100"
                                    height="100"
                                />
                            </div>
                            <div className="col">
                                <h3>Eddy Pieniazek</h3>
                                Head of Advisory at Ishka, Eddy is the original champion of today&apos;s aircraft
                                valuation and appraisal industry and founder of the first online aircraft valuation tool
                                in 1999.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    jumbotron(title, icon, content, buttons, background) {
        return (
            <div className={`jumbotron ${background} text-center`}>
                <div className="row m-2">
                    <div className="col">
                        <h1 className="font-bold">
                            <FontAwesomeIcon icon={icon} className="faded" />
                            &nbsp;&nbsp;{title}
                        </h1>
                    </div>
                </div>
                <div className="row m-2">
                    <div className="col lead">
                        <h3>{content}</h3>
                    </div>
                </div>
                <div className="row mb-3">{buttons}</div>
            </div>
        );
    }

    valuations() {
        return this.jumbotron(
            "VALUATIONS",
            faPlane,
            <React.Fragment>
                <p>A new approach to realistic, reliable values underpinned by Ishka&apos;s advanced methodologies.</p>
            </React.Fragment>,
            <React.Fragment>
                <div className="col pb-1">
                    <Link to="/valuations" onClick={() => sendToAmplitude(AMPL_SEARCH_INDIVIDUAL_BUTTON_CLICKED)}>
                        <button className="btn btn-primary btn-lg home-button">
                            SEARCH
                            <br />
                            <strong>INDIVIDUAL</strong>
                        </button>
                    </Link>
                </div>
                <div className="col pb-1">
                    <Link to="/portfolios" onClick={() => sendToAmplitude(AMPL_BUILD_MANAGE_PORTFOLIO_CLICKED)}>
                        <button className="btn btn-primary btn-lg home-button">
                            BUILD + MANAGE
                            <br />
                            <strong>PORTFOLIOS</strong>
                        </button>
                    </Link>
                </div>
            </React.Fragment>,
            "jumbotron-valuations"
        );
    }

    transactions() {
        return this.jumbotron(
            "TRANSACTIONS",
            faRandom,
            <React.Fragment>
                <p>Unprecedented transparency with real pricing on current and historical aircraft lease deals.</p>
            </React.Fragment>,
            <React.Fragment>
                <div className="col pb-1">
                    <Link to="/transactions" onClick={() => sendToAmplitude(AMPL_SEARCH_TRANSACTION_BUTTON_CLICKED)}>
                        <button className="btn btn-primary btn-lg home-button">
                            SEARCH
                            <br />
                            <strong>TRANSACTIONS</strong>
                        </button>
                    </Link>
                </div>
            </React.Fragment>,
            "jumbotron-transactions"
        );
    }

    card(icon, title, description) {
        return (
            <div className="card mb-2">
                <div className="row align-items-center mt-2 ml-2 mr-2">
                    <div className="col-auto">
                        <h2>
                            <FontAwesomeIcon icon={icon} className="faded" />
                        </h2>
                    </div>
                    <div className="col">
                        <h3>{title}</h3>
                    </div>
                </div>
                <div className="row align-items-center mb-3 ml-2 mr-2">
                    <div className="col-auto">
                        <h2>
                            <FontAwesomeIcon icon={icon} className="invisible" />
                        </h2>
                    </div>
                    <div className="col">{description}</div>
                </div>
            </div>
        );
    }

    foot() {
        return (
            <div className="row mb-4">
                <div className="col-lg">
                    <Link to="/methodology" className="home-card">
                        {this.card(
                            faTools,
                            "Our Methodology",
                            "Find out more about Transaction Economics and Ishka's process."
                        )}
                    </Link>
                </div>
                <div className="col-lg">
                    <Link to="/glossary" className="home-card">
                        {this.card(
                            faAlignLeft,
                            "Glossary",
                            "Terminology explained in all its glory. If in doubt, check here."
                        )}
                    </Link>
                </div>
                <div className="col-lg">
                    <Link to="/meet-the-experts" className="home-card">
                        {this.card(
                            faUserTie,
                            "Meet the Team",
                            "Our experts' track record is exceptional. Find out more."
                        )}
                    </Link>
                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <div className="row mb-4">
                    <div className="col">{this.lead()}</div>
                </div>
                <div className="row align-items-center row-eq-height">
                    <div className="col-lg-6 mb-4 ">{this.valuations()}</div>
                    <div className="col-lg-6 mb-4 ">{this.transactions()}</div>
                </div>
                {this.foot()}
            </React.Fragment>
        );
    }
}

export default HomeContainer;
