export const HYDRATE_TRANSACTIONS_FILTER = "HYDRATE_TRANSACTIONS_FILTER";
export const HYDRATE_TRANSACTIONS_FILTER_PENDING = "HYDRATE_TRANSACTIONS_FILTER_PENDING";
export const HYDRATE_TRANSACTIONS_FILTER_FULFILLED = "HYDRATE_TRANSACTIONS_FILTER_FULFILLED";
export const HYDRATE_TRANSACTIONS_FILTER_REJECTED = "HYDRATE_TRANSACTIONS_FILTER_REJECTED";

export const TRANSACTION_FILTER_CHOOSE_AIRCRAFT_VARIANT = "TRANSACTION_FILTER_CHOOSE_AIRCRAFT_VARIANT";

export const TRANSACTION_FILTER_DATE_START_CHANGE = "TRANSACTION_FILTER_DATE_START_CHANGE";
export const TRANSACTION_FILTER_DATE_END_CHANGE = "TRANSACTION_FILTER_DATE_END_CHANGE";

export const TRANSACTION_FILTER_RETRIEVE_STATS = "TRANSACTION_FILTER_RETRIEVE_STATS";

export const TRANSACTION_FILTER_RETRIEVE_VINTAGES = "TRANSACTION_FILTER/RETRIEVE_VINTAGES";
export const TRANSACTION_FILTER_RETRIEVE_VINTAGES_FULFILLED = "TRANSACTION_FILTER/RETRIEVE_VINTAGES_FULFILLED";

export const SELECT_CHARTING_PARAMTER = "SELECT_CHARTING_PARAMETER";

export const SELECT_CHARTING_X = "SELECT_CHARTING_X";
export const SELECT_CHARTING_Y = "SELECT_CHARTING_Y";
export const SELECT_CHARTING_CRITERIA = "SELECT_CHARTING_CRITERIA";

export const TRANSACTION_FILTER_VINTAGE_RANGE_FROM_CHANGE = "TRANSACTION_FILTER_VINTAGE_RANGE_FROM_CHANGE";
export const TRANSACTION_FILTER_VINTAGE_RANGE_TO_CHANGE = "TRANSACTION_FILTER_VINTAGE_RANGE_TO_CHANGE";

export const SELECT_GRAPH_TO_MAGNIFY = "SELECT_GRAPH_TO_MAGNIFY";
