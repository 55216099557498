export const FETCH_FUTURE_VALUES = "FETCH_FUTURE_VALUES";
export const FETCH_FUTURE_VALUES_PENDING = "FETCH_FUTURE_VALUES_PENDING";
export const FETCH_FUTURE_VALUES_FULFILLED = "FETCH_FUTURE_VALUES_FULFILLED";
export const FETCH_FUTURE_VALUES_REJECTED = "FETCH_FUTURE_VALUES_REJECTED";

export const RESET_FUTURE_VALUES = "RESET_FUTURE_VALUES";

export const PUSH_SEARCH_HISTORY = "PUSH_SEARCH_HISTORY";
export const PUSH_SEARCH_HISTORY_PENDING = "PUSH_SEARCH_HISTORY_PENDING";
export const PUSH_SEARCH_HISTORY_FULFILLED = "PUSH_SEARCH_HISTORY_FULFILLED";
export const PUSH_SEARCH_HISTORY_REJECTED = "PUSH_SEARCH_HISTORY_REJECTED";
