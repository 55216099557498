import React from "react";
import PageWrapper from "../shared/template/PageWrapper";
import IBox from "../shared/inspinia/IBox";

class Methodology extends React.Component {
    render() {
        return (
            <PageWrapper title="Methodology">
                <IBox title="Base Value">
                    <p>
                        Ishka’s Base Value opinion for a given aircraft type, age, and vintage is, at its core, based on
                        the following key inputs:
                    </p>
                    <ul>
                        <li>Historical and Current Relative Depreciation Rates for all commercial aircraft types</li>
                        <li>
                            Aircraft Position in the Production Life Cycle per type and variant (from initial build,
                            through maturity, to ‘end of line’ and sunset stages)
                        </li>
                        <li>Economic Life assumptions per type and variant</li>
                    </ul>
                    <p>
                        The outputs from our model are then fine-tuned to reflect any anticipated developments that
                        Ishka foresee in the future market environment, and other variables that can have a bearing on
                        performance, including anticipated world economic growth and oil price performance.
                    </p>
                    Process
                    <p>
                        In determining our Base Value forecasts for a given aircraft type and variant, Ishka has taken
                        into account the historical relative depreciation rates of that type (if it has a history) and
                        other aircraft types with similar features and liquidity, as well as the existing and
                        anticipated product life-cycle phase.
                    </p>
                    <p>
                        Ishka has constructed a view of the base value performance (or normalised market values) of a
                        range of aircraft types and variants with long histories and varied liquidity profiles to
                        calculate their relative depreciation rates over time for all vintages. For each aircraft type
                        we determined what we believe to be the ‘underlying economic value’ i.e., Base Value of the
                        aircraft as delivered, in each valuation year.
                    </p>
                    <p>
                        To determine the values to be forecast from today for each aircraft type/variant, we selected
                        suitable combinations of comparable aircraft types, considering their liquidity-based factors
                        such as classification, the number of aircraft in service, order backlogs, being a member of a
                        family of aircraft, technology position etc, as well as the number and concentration levels
                        regarding airline operators.
                    </p>
                    <p>
                        Consideration was also given to the historical value performance of these aircraft types
                        relative to their position in the production cycle, acknowledging that value performance is
                        often influenced by early production teething troubles, through to production maturity, and then
                        to the well documented ‘end of the line’ production effect). We identified the timings when an
                        aircraft type enters into service, enters its maturity production phase, reaches its sunset
                        years and ends production, and also took into consideration if there have been significant
                        changes in the types function or configuration.
                    </p>
                    <p>
                        For each aircraft type and variant monitored by Ishka, we assign an anticipated production life
                        cycle measured in years. From the period of entry into service, to the end of production, the
                        historical rates of relative depreciation in value of different vintages of comparable aircraft
                        types are then applied in blended form to the appropriate production stages that the subject
                        aircraft type is in, and a forecast base value curve is created for each vintage.
                    </p>
                    <p>
                        After an aircraft reaches the end of its production, we also apply a liquidity discount to the
                        incremental increase of depreciation from maturity to sunset stage, which is calculated
                        according to the past performance of aircraft types with similar liquidity profiles and longer
                        histories. This process enables us to generate curves from the valuation models for each
                        respective aircraft type which are then reviewed and subject to further revision by Ishka’s
                        Valuation Board, and which leads to a future value opinion that we believe best reflects the
                        underlying economic value of an aircraft, assuming stable economic conditions (‘Base Value’
                        definition).
                    </p>
                    <p>
                        We provide future value opinions for an aircraft type until it reaches a juncture where the base
                        value concept begins to lessen in its relevance. Such a point, we believe, is primarily
                        determined by the part-out value, i.e., the stage at which it conceptually becomes applicable
                        when the disassembly for parts would most probably result in a higher cash yield for the asset
                        “as-is” as compared to the value of the aircraft as a whole. We consider the main influencer of
                        part-out values to be the engines and use these as our basis to establish the future Base Value
                        cut-off point.
                    </p>
                </IBox>

                <IBox title="Transaction Data">
                    <p>
                        In order to better understand the actual trading market Ishka has an on-going effort to research
                        public source information in order to compile a detailed database of real aircraft transaction
                        information.
                    </p>
                    <p>
                        The data that has currently been captured covers transactions primarily from the last fifteen
                        years, but does extend as far back as the 1990s. Over 30,000 individual datapoints have been
                        captured, covering lease rates and payments, purchase prices, reserves, security deposits and
                        loans for over 2,000 individual aircraft operating with over 300 airlines in over 90 countries.
                    </p>
                    <p>
                        The source data that Ishka has captured includes the following fields:
                        <br />
                        Aircraft MSN (serial number)
                        <br />
                        Aircraft Registration
                        <br />
                        Aircraft Model
                        <br />
                        Aircraft Variant
                        <br />
                        Date Manufactured
                        <br />
                        Original Delivery Date
                    </p>
                    <p>
                        Lessee / Lessee Country / Lessee Credit Rating
                        <br />
                        Owner / Owner Jurisdiction
                        <br />
                        Immediate or Ultimate Parent
                        <br />
                        Head Lessor and Asset Manager
                    </p>
                    <p>
                        Purchase Date/Transaction Date
                        <br />
                        Monthly Lease Rent
                        <br />
                        Lease Rate Factor
                        <br />
                        Aircraft Age at Time of Transaction
                        <br />
                        Purchase or Sale Price
                        <br />
                        Maintenance Intangible
                        <br />
                        Lease Intangible
                        <br />
                        Bare Aircraft Value
                        <br />
                        Aircraft Net Book Value
                        <br />
                    </p>
                    <p>
                        Security Deposit (and equivalent in monthly rentals)
                        <br />
                        Letter of Credit
                        <br />
                        Maintenance Reserves / Supplemental Rent Balance
                        <br />
                        Lease Length (months)
                        <br />
                        Lease Start / Lease End
                        <br />
                        Engine Manufacturer
                        <br />
                        Engine Model
                    </p>
                    Loan Data (these can be Senior, Junior, Mezzanine etc). For each loan, the following fields are
                    recorded:
                    <table>
                        <tbody>
                            <tr>
                                <td>Lender(s)</td>
                                <td>&nbsp;&nbsp;&nbsp;Financing Type</td>
                            </tr>
                            <tr>
                                <td>Principal Amount</td>
                                <td>&nbsp;&nbsp;&nbsp;Interest</td>
                            </tr>
                            <tr>
                                <td>Floating rate</td>
                                <td>&nbsp;&nbsp;&nbsp;Initial LTV</td>
                            </tr>
                            <tr>
                                <td>Payable Monthly/Quarterly</td>
                                <td>&nbsp;&nbsp;&nbsp;Loan Start</td>
                            </tr>
                            <tr>
                                <td>Expected Maturity</td>
                                <td />
                            </tr>
                        </tbody>
                    </table>
                    <p />
                    <p>
                        The source data is captured in a raw format and often requires processing in order to distil the
                        relevant information. The data also sometimes refers to a collective of more than one aircraft,
                        and some manipulation is required to extract a meaningful representation of data for a single
                        aircraft or asset. Where this is not possible, the data is not used.
                    </p>
                    <p>
                        Therefore, in the context of a monthly lease rate, purchase price, or sale price as reported by
                        Ishka in the Transaction Economics database, the values attributed to each of these fields may
                        be an inferred rate or an inferred price, and one that is implied by the raw data, rather than
                        the exact value. For example, if the source data has quarterly or semi-annual or annual lease
                        rate amounts, these have been converted by Ishka to a monthly rental amount to enable
                        like-for-like comparison with other aircraft and data. Conversely, if the raw data reflects a
                        number of sisterships of the same type/variant/vintage, the data would be divided equally
                        between them to generate an inferred price or an amount on a per aircraft basis.
                    </p>
                    <p>
                        As you will appreciate, this data is extremely sensitive and some of the more sensitive
                        information is being retained, such as the aircraft MSN/Registration/Lessee/Lessor/Owner etc. in
                        order to ‘de-identify’ the actual aircraft involved in the transaction.
                    </p>
                    <p>
                        The fields that are visible in graphical form through the Transaction Economics database include
                        the following:
                    </p>
                    <p>Aircraft Type and Variant</p>
                    <p>Aircraft Age (at time of transaction)</p>
                    <p>
                        Sale/Purchase Price
                        <br />
                        This is the inferred or implied cost or value of the asset at the time of transaction.
                    </p>
                    <p>Year of Transaction – derived from the actual date of transaction</p>
                    <p>
                        Monthly Lease Rate
                        <br />
                        This is the inferred or implied monthly lease rate of the asset at the time of the transaction.
                    </p>
                    <p>
                        Lease Rate Factor (%)
                        <br />
                        This is the lease rate expressed as a percentage of the purchase price or value of the asset.
                    </p>
                    <p>
                        Maintenance Reserve Balance
                        <br />
                        Reserve balance collected against a lease contract at the time of reporting.
                    </p>
                    <p>
                        Lessee Credit Quality Today
                        <br />
                        Whilst we do not expose the actual lessee name, we have provided guidance regarding the lessees
                        current credit score as assessed by Ishka. (see Ishka Airline Credit Profiles for information
                        about the Ishka methodology).
                    </p>
                    <p>
                        Lessee Credit Quality Trend
                        <br />
                        We have also provided an indication of the direction in which the lessees creditworthiness is
                        currently perceived to be heading.
                    </p>
                    <p>
                        Lessee Business Model
                        <br />
                        We have also provided an indication of the lessee’s current business model.
                    </p>
                    <p>Updated: April 2021</p>
                </IBox>
            </PageWrapper>
        );
    }
}

export default Methodology;
