export const RETRIEVE_PURCHASE_PRICES = "RETRIEVE_PURCHASE_PRICES";
export const RETRIEVE_PURCHASE_PRICES_PENDING = "RETRIEVE_PURCHASE_PRICES_PENDING";
export const RETRIEVE_PURCHASE_PRICES_FULFILLED = "RETRIEVE_PURCHASE_PRICES_FULFILLED";
export const RETRIEVE_PURCHASE_PRICES_REJECTED = "RETRIEVE_PURCHASE_PRICES_REJECTED";

export const RETRIEVE_AIRCRAFT_REGISTRATION = "RETRIEVE_AIRCRAFT_REGISTRATION";
export const RETRIEVE_AIRCRAFT_REGISTRATION_PENDING = "RETRIEVE_AIRCRAFT_REGISTRATION_PENDING";
export const RETRIEVE_AIRCRAFT_REGISTRATION_FULFILLED = "RETRIEVE_AIRCRAFT_REGISTRATION_FULFILLED";
export const RETRIEVE_AIRCRAFT_REGISTRATION_REJECTED = "RETRIEVE_AIRCRAFT_REGISTRATION_REJECTED";

export const RESET_SELECTED_AIRCRAFT_REGISTRATION = "RESET_SELECTED_AIRCRAFT_REGISTRATION";
