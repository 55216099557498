import {
    FIND_AIRCRAFT_BASELINE_PENDING,
    FIND_AIRCRAFT_BASELINE_FULFILLED,
    FIND_AIRCRAFT_BASELINE_REJECTED,
    AUTOFILL_CONFIGURATION_FULFILLED,
    RESET_AIRCRAFT_BASELINE,
} from "../constants/aircraftBaselinesConstants";

const defaultConfiguration = {
    fetching: false,
    list: [],
    searchDone: false,
};

export const aircraftBaselines = (state = defaultConfiguration, action) => {
    switch (action.type) {
        case FIND_AIRCRAFT_BASELINE_PENDING:
            return { ...state, fetching: true };

        case FIND_AIRCRAFT_BASELINE_FULFILLED:
            return {
                ...state,
                fetching: false,
                list: action.payload.data,
                searchDone: true,
            };

        case FIND_AIRCRAFT_BASELINE_REJECTED:
            return { ...state, fetching: false };

        case AUTOFILL_CONFIGURATION_FULFILLED:
            return { ...state, list: [action.payload.data.aircraft_baseline], searchDone: action.autoselectBaseline };

        case RESET_AIRCRAFT_BASELINE:
            return defaultConfiguration;

        default:
            return state;
    }
};
