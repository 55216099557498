import {
    HYDRATE_AIRCRAFT_VARIANTS_PENDING,
    HYDRATE_AIRCRAFT_VARIANTS_FULFILLED,
    HYDRATE_AIRCRAFT_VARIANTS_REJECTED,
    SELECT_AIRCRAFT_VARIANT,
    RESET_AIRCRAFT_VARIANTS,
} from "../constants/aircraftVariantsConstants";
import { AUTOFILL_CONFIGURATION_FULFILLED } from "../constants/aircraftBaselinesConstants";

const defaultConfiguration = {
    fetching: false,
    selected: -1,
    list: [],
};

export const aircraftVariants = (state = defaultConfiguration, action) => {
    switch (action.type) {
        case HYDRATE_AIRCRAFT_VARIANTS_PENDING:
            return { ...state, fetching: true };

        case HYDRATE_AIRCRAFT_VARIANTS_FULFILLED:
            return {
                ...state,
                fetching: false,
                list: action.payload.data.map((av) => {
                    return {
                        value: av.id,
                        label: av.name,
                        type: av.name.slice(0, av.name.length - av.code.length - 1),
                        rating:
                            av.aircraft_variant_price_factor &&
                            av.aircraft_variant_price_factor.asset_risk &&
                            av.aircraft_variant_price_factor.asset_risk.rating,
                    };
                }),
            };

        case HYDRATE_AIRCRAFT_VARIANTS_REJECTED:
            return { ...state, fetching: false };

        case SELECT_AIRCRAFT_VARIANT:
            return { ...state, selected: action.payload.value };

        case AUTOFILL_CONFIGURATION_FULFILLED:
            return {
                ...state,
                selected: action.payload.data.aircraft_variant.selected,
            };

        case RESET_AIRCRAFT_VARIANTS:
            return {
                ...state,
                selected: -1,
            };

        default:
            return state;
    }
};
