import {
    HYDRATE_ENGINE_SERIES_PENDING,
    HYDRATE_ENGINE_SERIES_FULFILLED,
    HYDRATE_ENGINE_SERIES_REJECTED,
    SELECT_ENGINE_SERIES,
    RESET_ENGINE_SERIES,
} from "../constants/engineSeriesConstants";

const defaultConfiguration = {
    fetching: false,
    selected: null,
    list: [],
};

export const engineSeries = (state = defaultConfiguration, action) => {
    switch (action.type) {
        case HYDRATE_ENGINE_SERIES_PENDING:
            return { ...state, fetching: true };

        case HYDRATE_ENGINE_SERIES_FULFILLED:
            return {
                ...state,
                fetching: false,
                list: action.payload.data.map((es) => {
                    return { value: es.id, label: es.code };
                }),
            };

        case HYDRATE_ENGINE_SERIES_REJECTED:
            return { ...state, fetching: false };

        case SELECT_ENGINE_SERIES:
            return { ...state, selected: action.payload };

        case RESET_ENGINE_SERIES:
            return defaultConfiguration;

        default:
            return state;
    }
};
