import {
    FETCH_TRANSACTIONS_DATAPOINTS_FULFILLED,
    RESET_TRANSACTIONS_DATAPOINTS,
} from "../constants/fetchDataConstants";

export const transactionsPurchasePrices = (state = {}, action) => {
    switch (action.type) {
        case FETCH_TRANSACTIONS_DATAPOINTS_FULFILLED: {
            let data = action.payload.data.purchase_prices;
            if (data == null) {
                data = [];
            }
            return {
                data: data,
                aircraft_variant_name: action.payload.data.aircraft_variant_name,
                xAxis: action.payload.data.x_axis,
                yAxis: action.payload.data.y_axis,
                criteria: action.payload.data.criteria,
            };
        }
        case RESET_TRANSACTIONS_DATAPOINTS:
            return {};

        default:
            return state;
    }
};
