import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions/authActionCreators";
import { Link } from "react-router-dom";
import { LOCAL_STORAGE_LOGIN_EMAIL } from "../startup/AuthApp";
import PropTypes from "prop-types";
import IBox from "../../inspinia/IBox";

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: (this.props.storageAvailable && localStorage.getItem(LOCAL_STORAGE_LOGIN_EMAIL)) || "",
            emailValid: true,
            progress: false,
        };
    }

    onEmailChange = (event) => {
        this.setState({ email: event.target.value });
        this.validateEmail(event.target.value);
    };

    forgotPassword = async (event) => {
        event.preventDefault();
        this.setState({ progress: true });
        this.props
            .forgotPassword(this.state.email.trim())
            .then(() => {
                this.setState({ progress: false });
                this.props.history.push("/login");
            })
            .catch(() => {
                this.setState({ progress: false });
            });
    };

    validateEmail = (value) => {
        this.setState({
            emailValid: value.trim().length === 0 || value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i),
        });
    };

    static errorClass(valid) {
        return valid ? "" : "has-error";
    }

    render() {
        return (
            <div className="row">
                <div className="col-lg-4" />
                <div className="col-lg-4">
                    <IBox title="Forgot your password?">
                        <div className="row">
                            <div className="col-sm-12">
                                <form onSubmit={(e) => this.forgotPassword(e)}>
                                    <div className={`form-group ${ForgotPassword.errorClass(this.state.emailValid)}`}>
                                        <label>Email</label>
                                        <input
                                            type="text"
                                            autoComplete="email"
                                            placeholder="Enter email"
                                            required={true}
                                            value={this.state.email}
                                            autoFocus={true}
                                            className="form-control"
                                            onChange={(e) => this.onEmailChange(e)}
                                        />
                                    </div>
                                    <button
                                        className="btn btn-primary"
                                        disabled={
                                            this.state.email.trim().length === 0 ||
                                            !this.state.emailValid ||
                                            this.state.progress
                                        }
                                    >
                                        Send me reset password instructions
                                    </button>
                                    <div className="float-right">
                                        <Link to="/login">Log in</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </IBox>
                </div>
                <div className="col-lg-4" />
            </div>
        );
    }
}

ForgotPassword.propTypes = {
    storageAvailable: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    forgotPassword: PropTypes.func.isRequired,
};

export default connect(null, actions)(ForgotPassword);
