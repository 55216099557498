import React from "react";
import { connect } from "react-redux";
import { signInUser } from "../actions/authActionCreators";
import { Link } from "react-router-dom";
import { LOCAL_STORAGE_LOGIN_EMAIL } from "../startup/AuthApp";
import PropTypes from "prop-types";
import IBox from "../../inspinia/IBox";
import { addFlashMessage } from "../../flash_message/flashMessageActionCreators";
import * as queryString from "query-string";

class Login extends React.Component {
    constructor(props) {
        super(props);
        const storageAvailable = this.props.storageAvailable;
        this.state = {
            storageAvailable: storageAvailable,
            rememberEmail: storageAvailable && localStorage.getItem(LOCAL_STORAGE_LOGIN_EMAIL) !== null,
            email: (storageAvailable && localStorage.getItem(LOCAL_STORAGE_LOGIN_EMAIL)) || "",
            emailValid: true,
            password: "",
            inProgress: false,
        };
        const values = queryString.parse(this.props.location ? this.props.location.search : "");
        const authFailed = values["AuthFailed"];
        if (authFailed === "NotAuthorized") {
            this.props.addFlashMessage({
                type: "error",
                message: "You don't have access to this application.",
            });
        }
        if (authFailed === "InternalError") {
            this.props.addFlashMessage({
                type: "error",
                message: "An error occurred during auto login. Please log in manually.",
            });
        }
    }

    onEmailChange = (event) => {
        this.setState({ email: event.target.value });
        this.validateEmail(event.target.value);
    };

    onPasswordChange = (event) => {
        this.setState({ password: event.target.value });
    };

    onRememberEmailChange = (e) => {
        this.setState({ rememberEmail: e.target.checked });
    };

    auth = async (event) => {
        event.preventDefault();
        this.setState({
            inProgress: true,
        });
        this.props
            .signInUser(this.state.email, this.state.password)
            .then(() => {
                if (this.state.rememberEmail) {
                    localStorage.setItem(LOCAL_STORAGE_LOGIN_EMAIL, this.state.email);
                } else {
                    localStorage.removeItem(LOCAL_STORAGE_LOGIN_EMAIL);
                }
                this.props.history.push("/");
            })
            .catch(() => {
                this.setState({
                    password: "",
                    inProgress: false,
                });
            });
    };

    validateEmail = (value) => {
        this.setState({
            emailValid: value.trim().length === 0 || value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i),
        });
    };

    static errorClass(valid) {
        return valid ? "" : "has-error";
    }

    render() {
        return (
            <div className="middle-box">
                <IBox title="Log in with your Ishka account">
                    <form onSubmit={(e) => this.auth(e)}>
                        <div className={`form-group ${Login.errorClass(this.state.emailValid)}`}>
                            <label>Email</label>
                            <input
                                type="email"
                                className="form-control"
                                value={this.state.email}
                                autoComplete="email"
                                placeholder="Enter email"
                                autoFocus={!this.state.rememberEmail}
                                onChange={(e) => this.onEmailChange(e)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input
                                type="password"
                                className="form-control"
                                value={this.state.password}
                                placeholder="Password"
                                autoFocus={this.state.rememberEmail}
                                onChange={(e) => this.onPasswordChange(e)}
                            />
                        </div>

                        {this.state.storageAvailable && (
                            <div className="form-group">
                                <input
                                    type="checkbox"
                                    checked={this.state.rememberEmail}
                                    onChange={(e) => this.onRememberEmailChange(e)}
                                />
                                &nbsp;
                                <label>Remember email on this browser</label>
                            </div>
                        )}

                        <button
                            className="btn btn-primary"
                            disabled={
                                this.state.inProgress ||
                                this.state.email.trim().length === 0 ||
                                !this.state.emailValid ||
                                this.state.password.length === 0
                            }
                        >
                            Log In
                        </button>
                        <div className="float-right">
                            <Link to="/login/forgot">Forgot your password?</Link>
                        </div>
                    </form>
                </IBox>
            </div>
        );
    }
}

Login.propTypes = {
    storageAvailable: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    signInUser: PropTypes.func.isRequired,
    addFlashMessage: PropTypes.func.isRequired,
};

export default connect(null, { signInUser, addFlashMessage })(Login);
