export const SELECT_MTOW_UNIT = "SELECT_MTOW_UNIT";
export const TYPE_MTOW = "TYPE_MTOW";
export const CHECK_MTOW_VALUE = "CHECK_MTOW_VALUE";

export const HYDRATE_MTOW = "HYDRATE_MTOW";
export const HYDRATE_MTOW_PENDING = "HYDRATE_MTOW_PENDING";
export const HYDRATE_MTOW_FULFILLED = "HYDRATE_MTOW_FULFILLED";
export const HYDRATE_MTOW_REJECTED = "HYDRATE_MTOW_REJECTED";

export const RESET_MTOW = "RESET_MTOW";
