import {
    EV_LEASE_START_SELECT,
    EV_LEASE_END_SELECT,
    EV_LEASE_RATE_SELECT,
    EV_PAYMENT_FREQUENCY_SELECT,
    EV_PAYMENT_TYPE_SELECT,
    EV_RETURN_CONDITION_SELECT,
    EV_DISCOUNT_RATE_CREDIT_RISK_SELECT,
    EV_DISCOUNT_RATE_ASSET_RISK_SELECT,
    EV_PRESENT_VALUE_DATE_SELECT,
    EV_PARAMS_RESET,
    EV_RESIDUAL_VALUE,
    EV_FETCH_RV_FULFILLED,
    EV_FETCH_RV_PENDING,
    EV_FETCH_RV_REJECTED,
    EV_ASSET_RISK_RETRIEVE_FULFILLED,
    EV_LESSEE_RISK_RETRIEVE_FULFILLED,
    EV_DISCOUNT_RATE_CREDIT_RISK_CUSTOM_VALUE,
    EV_DISCOUNT_RATE_ASSET_RISK_CUSTOM_VALUE,
} from "../constants/encumberedConstants";
import { SELECT_AIRCRAFT_VARIANT } from "../constants/aircraftVariantsConstants";
import { dateString, formatNumber } from "../../../shared/ishkaUtilities";
import { AUTOFILL_CONFIGURATION_FULFILLED } from "../constants/aircraftBaselinesConstants";

const defaultConfiguration = {
    leaseStart: "",
    leaseEnd: "",
    leaseRate: 0,
    paymentFrequency: "MONTHLY",
    paymentType: "ADVANCE",
    creditRiskDiscount: {
        list: [],
        selected: "GOOD",
        custom: null,
    },
    assetRiskDiscount: {
        list: [],
        selected: "GOOD",
        custom: null,
    },
    returnCondition: "FULL-LIFE",
    presentValueDate: "",
    residualValue: "",
    rvError: null,
};

export const encumbered = (state = defaultConfiguration, action) => {
    switch (action.type) {
        case EV_PARAMS_RESET: {
            return {
                ...defaultConfiguration,
                creditRiskDiscount: {
                    list: state.creditRiskDiscount.list,
                    selected: "GOOD",
                },
                assetRiskDiscount: {
                    list: state.assetRiskDiscount.list,
                    selected: "GOOD",
                },
            };
        }
        case EV_LEASE_START_SELECT: {
            if (action.payload === "") {
                return {
                    ...state,
                    leaseStart: "",
                    leaseEnd: "",
                };
            } else {
                return {
                    ...state,
                    leaseStart: new Date(action.payload).toISOString().slice(0, 10),
                };
            }
        }
        case EV_LEASE_END_SELECT: {
            let date = action.payload === "" ? "" : dateString(new Date(action.payload));
            return {
                ...state,
                leaseEnd: date,
            };
        }

        case EV_LEASE_RATE_SELECT:
            return {
                ...state,
                leaseRate: action.payload,
            };

        case EV_PAYMENT_FREQUENCY_SELECT:
            return {
                ...state,
                paymentFrequency: action.payload,
            };

        case EV_PAYMENT_TYPE_SELECT:
            return {
                ...state,
                paymentType: action.payload,
            };
        case EV_RETURN_CONDITION_SELECT:
            return {
                ...state,
                returnCondition: action.payload,
            };

        case EV_DISCOUNT_RATE_CREDIT_RISK_SELECT:
            return {
                ...state,
                creditRiskDiscount: {
                    ...state.creditRiskDiscount,
                    selected: action.payload,
                    custom: action.payload !== "CUSTOM" ? null : state.creditRiskDiscount.custom,
                },
            };

        case EV_DISCOUNT_RATE_CREDIT_RISK_CUSTOM_VALUE:
            return {
                ...state,
                creditRiskDiscount: {
                    ...state.creditRiskDiscount,
                    custom: action.payload,
                },
            };

        case EV_DISCOUNT_RATE_ASSET_RISK_SELECT:
            return {
                ...state,
                assetRiskDiscount: {
                    ...state.assetRiskDiscount,
                    selected: action.payload,
                    custom: action.payload !== "CUSTOM" ? null : state.assetRiskDiscount.custom,
                },
            };

        case EV_DISCOUNT_RATE_ASSET_RISK_CUSTOM_VALUE:
            return {
                ...state,
                assetRiskDiscount: {
                    ...state.assetRiskDiscount,
                    custom: action.payload,
                },
            };

        case EV_PRESENT_VALUE_DATE_SELECT: {
            let date = action.payload === "" ? "" : new Date(action.payload).toISOString().slice(0, 10);
            let nextState = {};
            nextState.presentValueDate = date;
            if (date === "") {
                nextState.residualValue = "";
            }
            return {
                ...state,
                ...nextState,
            };
        }

        case EV_FETCH_RV_FULFILLED: {
            return { ...state, residualValue: action.payload.data.residual_value };
        }

        case EV_FETCH_RV_PENDING: {
            return { ...state, rvError: null };
        }

        case EV_FETCH_RV_REJECTED: {
            return {
                ...state,
                rvError:
                    "We don't have an encumbered valuation possible for that Lease End Date with that combination of aircraft and vintage",
                residualValue: "",
            };
        }

        case EV_RESIDUAL_VALUE:
            return {
                ...state,
                residualValue: action.payload,
            };

        case EV_LESSEE_RISK_RETRIEVE_FULFILLED:
            return {
                ...state,
                creditRiskDiscount: {
                    ...state.creditRiskDiscount,
                    list: [
                        ...action.payload.data
                            .filter((risk) => risk.rating !== "BANKRUPT")
                            .map((risk) => {
                                return {
                                    value: risk.rating,
                                    label: `${risk.label} ${formatNumber(risk.value, "0.00%")}`,
                                };
                            }),
                        { value: "CUSTOM", label: "Custom" },
                    ],
                },
            };
        case EV_ASSET_RISK_RETRIEVE_FULFILLED:
            return {
                ...state,
                assetRiskDiscount: {
                    ...state.assetRiskDiscount,
                    list: [
                        ...action.payload.data.map((risk) => {
                            return {
                                value: risk.rating,
                                label: `${risk.label} ${formatNumber(risk.value, "0.00%")}`,
                            };
                        }),
                        { value: "CUSTOM", label: "Custom" },
                    ],
                },
            };
        case SELECT_AIRCRAFT_VARIANT:
            return {
                ...state,
                assetRiskDiscount: {
                    ...state.assetRiskDiscount,
                    ...(action.payload.rating && { selected: action.payload.rating }),
                },
            };
        case AUTOFILL_CONFIGURATION_FULFILLED: {
            if (action.payload.config.params["portfolio_entry_id"] !== undefined) {
                const data = action.payload.data;
                return {
                    ...state,
                    presentValueDate: dateString(new Date()),
                    ...(data.lease_start_at && { leaseStart: data.lease_start_at }),
                    ...(data.lease_end_at && { leaseEnd: data.lease_end_at }),
                    ...(data.lease_rate && { leaseRate: data.lease_rate }),
                    ...(data.payment_frequency && { paymentFrequency: data.payment_frequency }),
                    ...(data.payment_type && { paymentType: data.payment_type }),
                    creditRiskDiscount: {
                        ...state.creditRiskDiscount,
                        ...(data.lessee_credit_discount && {
                            selected: data.lessee_credit_discount,
                        }),
                        ...(data.lessee_credit_discount_rate && {
                            custom: data.lessee_credit_discount_rate * 100,
                        }),
                    },
                    assetRiskDiscount: {
                        ...state.assetRiskDiscount,
                        ...(data.asset_risk_discount && {
                            selected: data.asset_risk_discount,
                        }),
                        ...(data.asset_risk_discount_rate && {
                            custom: data.asset_risk_discount_rate * 100,
                        }),
                    },
                };
            } else {
                return state;
            }
        }
        default:
            return state;
    }
};
