import { RESET_INFLATION, SELECT_INFLATION } from "../constants/inflationConstants";
import { AUTOFILL_CONFIGURATION_FULFILLED } from "../constants/aircraftBaselinesConstants";

const MIN_INFLATION = 0.0;
const DEFAULT_INFLATION = 2.0;
const MAX_INFLATION = 10.0;
const WARNING = `Please input a value between ${MIN_INFLATION.toFixed(2)}% to ${MAX_INFLATION.toFixed(2)}%`;

const defaultConfiguration = {
    selected: DEFAULT_INFLATION,
    displayGuidance: null,
};

export const inflation = (state = defaultConfiguration, action) => {
    switch (action.type) {
        case SELECT_INFLATION:
            return setInflation(action.payload, state);

        case AUTOFILL_CONFIGURATION_FULFILLED:
            if (action.payload.data.forecast_inflation) {
                const inflation = action.payload.data.forecast_inflation * 100;
                return setInflation(inflation);
            } else {
                return state;
            }
        case RESET_INFLATION:
            return defaultConfiguration;
        default:
            return state;
    }
};

function setInflation(inflation, state) {
    if (inflation === state?.selected) {
        return state;
    } else if (inflation < MIN_INFLATION) {
        return { ...state, selected: MIN_INFLATION.toFixed(2), displayGuidance: WARNING };
    } else if (inflation > MAX_INFLATION) {
        return { ...state, selected: MAX_INFLATION.toFixed(2), displayGuidance: WARNING };
    } else {
        return { ...state, selected: inflation, displayGuidance: null };
    }
}
