import React from "react";
import IshkaLogo from "./IshkaLogo";

class NavBarNotLogged extends React.Component {
    render() {
        return (
            <nav className="navbar-default navbar-static-side" role="navigation">
                <div className="sidebar-collapse">
                    <ul className="nav metismenu" id="side-menu">
                        <IshkaLogo />
                    </ul>
                </div>
            </nav>
        );
    }
}

export default NavBarNotLogged;
