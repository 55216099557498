import { SELECT_VINTAGE, RESET_VINTAGE } from "../constants/vintageConstants";
import { AUTOFILL_CONFIGURATION_FULFILLED } from "../constants/aircraftBaselinesConstants";

const defaultConfiguration = {
    selected: null,
    list: [],
};

export const vintage = (state = defaultConfiguration, action) => {
    switch (action.type) {
        case SELECT_VINTAGE:
            return {
                ...state,
                selected: action.payload,
            };

        case RESET_VINTAGE:
            return defaultConfiguration;

        case AUTOFILL_CONFIGURATION_FULFILLED:
            return {
                selected: action.payload.data.vintages.selected,
                list: action.payload.data.vintages.list == null ? [] : action.payload.data.vintages.list,
            };
        default:
            return state;
    }
};
