import React from "react";
import PageWrapper from "../shared/template/PageWrapper";
import IBox from "../shared/inspinia/IBox";

class Glossary extends React.Component {
    render() {
        return (
            <PageWrapper title="Glossary">
                <IBox title="Glossary and Value Definitions">
                    <p>
                        The appraisal definitions used in Transaction Economics are synonymous with and generally
                        conform to those of the International Society of Transport Aircraft Trading (“ISTAT”) which were
                        originally prescribed in 1994 and updated in 2020 (Revision 9).
                    </p>
                    <h3>Current Market Value</h3>
                    <p>
                        Ishka&apos;s Market Value (or Current Market Value if the value pertains to the present time at
                        analysis) reflects our opinion of the <strong>likely trading price</strong> that may best be
                        achieved for an <strong>unencumbered aircraft under the market conditions</strong> that are
                        perceived to exist at the given point in time.
                        <br />
                        Market Value assumes a hypothetical <strong>single aircraft sale</strong> between{" "}
                        <strong>willing and knowledgeable parties</strong>, under <strong>no duress</strong> to complete
                        a sale, in an <strong>open and unrestricted transaction</strong> negotiated on an{" "}
                        <strong>arm’s-length</strong> basis, for cash or equivalent consideration, and given an adequate
                        amount of time for marketing exposure (we assume 12 months is appropriate). It also assumes the
                        aircraft is <strong>free of any onerous restrictions</strong> with respect to its title or
                        ownership.
                    </p>
                    <p>
                        Our market value opinion is based on an ongoing review of market activity and known transaction
                        data involving the subject aircraft type and its counterparts, and additionally leverages the
                        sentiments expressed by the wider market and its participants. The valuation also embodies our
                        view of demand for the type, its availability on the market, and specification. An unencumbered
                        asset will typically deliver a lower &apos;value&apos; compared to an encumbered (leased) asset
                        as it does not benefit from the perceived security of an associated, contracted lease stream.
                    </p>
                    <h3>Encumbered Value</h3>
                    <p>
                        The Lease Embedded Value (LEV) or Encumbered Value is calculated by means of the standard
                        Discounted Cash Flow (DCF) valuation methodology, whereby the Net Present Value (NPV) of the
                        periodic cash flows associated with the lease are discounted to obtain today’s value, in order
                        to account for the time value of money.
                    </p>
                    <p>
                        Two different types of cash flows are considered when calculating the LEV, namely contractual
                        lease income paid in accordance with the lease contract and the estimated residual value of the
                        aircraft at the end of the lease, when the lessee returns the aircraft to the lessor at the end
                        of the contracted lease term. Full-life values are typically referred to if a lease involves the
                        collection of cash maintenance reserves, or supplemental rent, over a regularly defined period
                        throughout the whole duration of the lease
                        <br />
                        The user will be allowed to input their own choice of the default discount rate values prompted
                        by the interface.
                    </p>
                    Calculating the Present Value (PV) or Net Present Value (NPV) requires the following inputs:
                    <ul>
                        <li>Aircraft Type, Variant and Age</li>
                        <li>Lessee creditworthiness score (discount rate)</li>
                        <li>Asset risk score (discount rate)</li>
                        <li>Lease Rate</li>
                        <li>Remaining Lease Term</li>
                        <li>Payments made in advance or in arrears</li>
                        <li>Return Condition assumptions at lease end</li>
                    </ul>
                    <p>
                        An encumbered asset will typically carry a higher &apos;value&apos; compared to an unencumbered
                        (‘naked’) asset as it benefits from the security of an associated and contracted lease stream.
                    </p>
                    The values of the default discount rates corresponding to each type of cash flow are as follows:
                    <ol>
                        <li>
                            For the periodic cash flows related to the lease contract, i.e. the periodic lease rental
                            payments to the lessor: each of these payments is by default discounted at a flat rate which
                            reflects the (non-diversifiable portion of) the risk inherent in the periodic lease rental
                            payments, i.e. the lessee airline credit risk. Each airline is assigned to one of three
                            credit risk buckets, corresponding to a High/Medium/Low credit risk. The default values of
                            the discount rates corresponding to each of these airline credit buckets are calculated as
                            the average pre-tax cost of debt, as reported in the annual financial accounts of the
                            airline, where the average is taken across all airlines in the associated risk bucket. Their
                            values are as follows:
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Good (BBB- and above)</td>
                                        <td>&nbsp;&nbsp;&nbsp;4.20%</td>
                                    </tr>
                                    <tr>
                                        <td>Average (BB+ to B+)</td>
                                        <td>&nbsp;&nbsp;&nbsp;5.00%</td>
                                    </tr>
                                    <tr>
                                        <td>Weak (B and below)</td>
                                        <td>&nbsp;&nbsp;&nbsp;6.00%</td>
                                    </tr>
                                </tbody>
                            </table>
                            Users can also input their own discount rates which they deem relevant to their valuations.
                        </li>
                        <li>
                            For the Residual Value (RV) of the aircraft at the end of the lease contract: this single
                            cash flow occurring at the end of the lease term is by default discounted at the rate which
                            reflects the (non-diversifiable portion of) risk inherent in the RV of this aircraft type.
                            There are various methods to calculate aircraft risk discount rate. Ishka has chosen the
                            capitalisation rate as the default, which is, by definition, net operating income divided by
                            current market values. The default values of the discount rates corresponding to each
                            aircraft type are set at three different liquidity levels corresponding to the lease income,
                            discounted maintenance reserves at end of lease, financing costs and purchase/sale price
                            obtained from our historical transactions database. Their values are summarised in the table
                            below:
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Good</td>
                                        <td>&nbsp;&nbsp;&nbsp;4.75%</td>
                                    </tr>
                                    <tr>
                                        <td>Average</td>
                                        <td>&nbsp;&nbsp;&nbsp;5.75%</td>
                                    </tr>
                                    <tr>
                                        <td>Weak</td>
                                        <td>&nbsp;&nbsp;&nbsp;6.75%</td>
                                    </tr>
                                </tbody>
                            </table>
                            Users can also input their own discount rates which they deem relevant to their valuations.
                        </li>
                    </ol>
                    Main assumptions
                    <br />
                    The main methodology assumptions underlying the above outlined LEV valuation methodology are as
                    follows:
                    <ul>
                        <li>
                            No early termination or extension of the lease contract: the lessee nor the lessor have the
                            option to terminate the lease early nor to extend the lease contract.
                        </li>
                        <li>
                            It is appropriate to use the same discount rate to discount each of the contractual lease
                            rate payments, in spite of their different duration.
                        </li>
                        <li>
                            Any tax effects are ignored in the calculation of the default values of the discount rates,
                            i.e. the default values of the discount rates are pre-tax.
                        </li>
                    </ul>
                    Ishka’s LEV calculation also features the following implementation assumptions:
                    <ul>
                        <li>
                            The contractual lease rate is constant over the entire lease term. Lease rate payments that
                            vary over time, or lease rate payments featuring an irregular schedule cannot be
                            accommodated.
                        </li>
                        <li>
                            The periodic lease rate payments are either at the beginning or at the end of each period.
                        </li>
                        <li>
                            Representation of airline credit risk: the airline credit risk is bucketed as being “Good”,
                            “Average” or “Weak”, and each airline credit risk bucket is mapped to a single discount
                            rate.
                        </li>
                        <li>
                            Representation of the asset risk: the asset risk is bucketed as being “Good”, “Average” or
                            “Weak”, and each asset risk bucket is mapped to a single discount rate.
                        </li>
                        <li>
                            All rates (interest rates, discount rates) are expressed on an annualised basis, unless
                            otherwise stated.
                        </li>
                    </ul>
                    <h3>Base Value</h3>
                    <p>
                        As per the ISTAT definition, a Base Value represents Ishka’s opinion of the{" "}
                        <strong>underlying economic value</strong> of an aircraft in an open, unrestricted,{" "}
                        <strong>stable market environment</strong> with a reasonable{" "}
                        <strong>balance of supply and demand</strong>, and assumes full consideration of its “highest
                        and best use.” An aircraft’s Base Value is founded in historical value performance and in the
                        projection of value trends and presumes an{" "}
                        <strong>arm’s-length, cash transaction between willing, able and knowledgeable parties</strong>,
                        acting prudently, with an <strong>absence of duress</strong> and with a reasonable period of
                        time available for marketing. In most cases, the Base Value of an aircraft assumes its{" "}
                        <strong>physical condition is average</strong> for an aircraft of its type and age, and its
                        maintenance time status is at ‘half-life’ (aka mid-life or mid-time) or benefiting from above
                        average maintenance status if it is new or nearly new, as the case may be.
                    </p>
                    <p>
                        As <strong>Base Value is related to ‘normalised’ long-term market trends</strong>, the
                        definition is commonly applied to projections of residual values. Alternative methodologies may
                        be more suited to reflect situations such as those relating to rental streams, market driven
                        analysis or less mainstream and more unusual aircraft.
                    </p>
                    <h3>Market Lease Rate</h3>
                    An Operating Lease Rate (or Market Lease Rate) is a function of a number of variables. These include
                    amongst others:
                    <ul>
                        <li>Credit rating of the lessee</li>
                        <li>Anticipated residual value of the asset (the current value may also be relevant)</li>
                        <li>
                            Asset liquidity profile which includes the general supply of and demand for, the asset type.
                        </li>
                        <li>Term of the lease</li>
                        <li>Lessors desired margin / yield curve</li>
                        <li>Costs of ownership and overhead level</li>
                        <li>
                            Lease rates attainable by competing assets (reflective of market conditions/availability)
                        </li>
                    </ul>
                    <p>
                        The number of variables involved means that it is difficult to say, in isolation, what lease
                        rate any particular aircraft should command at a point in time. However, it is possible to say
                        what an ‘average’ aircraft would typically command. Ishka’s Market Lease Rate is therefore
                        indicative of a realisable monthly lease rate for the particular aircraft at the point in time
                        in question.
                    </p>
                    <p>
                        Market circumstances (or an appraisers’ perception of the market) are often the key variable
                        factor in the equation. Ishka’s perception of market circumstances are a function of the
                        transactions which we aware of, the general macroeconomic environment, developments in the
                        market for any particular aircraft type and the market conditions which pertain to those
                        competing aircraft types.
                    </p>
                    <p>
                        Through our Transaction Economics platform, we are aware of many actual sales and leasing
                        transactions involving commercial aircraft. Using this and other supporting information, we are
                        regularly reassessing our lease rate views, especially when we perceive that there are changes
                        in the supply of, and demand for, any specific aircraft type (even though no actual transactions
                        may have taken place).
                    </p>
                    <h3>Soft Market Value</h3>
                    <p>
                        Ishka&apos;s Soft Market Value opinion is intended to represent the likely trading price that
                        may best be achieved for an unencumbered aircraft under conditions that reflect the ‘low point’
                        in a recessionary economic environment, or other externally influenced event, in key traffic
                        generating regions. Recessionary environments have historically depressed market values, due to
                        increased availability and reduced demand for aircraft.
                    </p>
                    <p>
                        The Soft Market Value is generated as a consistent discount over time to the aircraft’s
                        equivalent Base Value. The impact can vary, depending on the assets popularity and liquidity
                        rating.
                    </p>
                    <h3>Peak Market Value</h3>
                    <p>
                        Ishka&apos;s Peak Market Value opinion is intended to represent the likely trading price that
                        may best be achieved for an unencumbered aircraft under conditions that reflect the ‘high point’
                        in a booming economic environment in key traffic generating regions. Such environments have
                        historically delivered a premium to market values, due to decreased availability and heightened
                        demand for aircraft.
                    </p>
                    <p>
                        The Peak Market Value is generated as a consistent premium over time to the aircraft’s
                        equivalent Base Value. The impact can vary, depending on the assets popularity and liquidity
                        rating. A Peak Market Value will typically generate less of a change to Base Value in percentage
                        terms, than a Soft Market Value. For example, an Airbus A320 may have a Peak Market Value of
                        Base Value +10% whereas the Soft Market Value may be Base Value -20% - this range is coming
                        under enhanced scrutiny given the severely stressed trading market since March 2020
                    </p>
                    <h3>Half-Life (Maintenance Condition)</h3>
                    <p>
                        The term “Half-Life” is a recognised industry standard and refers to the maintenance condition
                        of an asset. It reflects a position where the airframe, engines, landing gear, APU, propellers
                        (where appropriate) and all major components are half-way between their last and their next
                        scheduled major overhaul, inspection, performance restoration/shop visit as applicable. In
                        addition each engines’ Life Limited Parts (LLPs) also have 50% of their certified lives
                        remaining.
                    </p>
                    <p>
                        Using ‘half-life’ status as a standard/benchmark enables aircraft and their respective values to
                        be compared against each other to a set, defined maintenance condition, thereby removing one of
                        the variables that can have a major effect on overall asset value.
                    </p>
                    <p>
                        It should be noted that Ishka’s online ‘half-life’ values for the youngest of aircraft (from new
                        up to 3 years of age) will reflect “half-life or better” status, in order to provide a better
                        representation of the ‘above half-life’ status of new aircraft. (It would normally take a few
                        years of utilisation for an aircraft to move from new, ‘full-life’ status to something closer to
                        a physical ‘half-life’ condition).
                        <br />
                        Consequently, two years after delivery, the online value is closer to reflecting ‘half-life’
                        status and from three years of age and onwards, is representative of ‘half-life’ condition.
                        <br />
                        For the youngest aircraft, a maintenance adjusted ‘desktop’ appraisal would provide the most
                        accurate representation of value.
                    </p>
                    <h3>‘Full-Life’ and ‘Run Out’ (Maintenance Condition)</h3>
                    <p>
                        ‘Full-Life’ reflects a position where the airframe, engines, landing gear, APU, propellers
                        (where appropriate) and all major components are fresh from their last scheduled major overhaul,
                        inspection, performance restoration/shop visit. Unless otherwise advised, each engines’ Life
                        Limited Parts (LLPs) also have 100% of their certified lives remaining. The concept of
                        ‘full-life’ is very much a leasing market concept, based around the return condition of an
                        aircraft. Full-life return conditions can be applicable when a new aircraft is delivered to a
                        lessee or a price has been agreed with a lessee for the aircraft that will allow for an ‘as new’
                        condition at return of the lease period, through a combination of the physical condition and/or
                        applicable end of lease financial compensation.
                    </p>
                    <p>
                        ‘Run Out’ reflects a position where, again, the airframe, engines, landing gear, APU, propellers
                        (where appropriate) and all major components have minimal hours, cycles or calendar time
                        remaining to their next scheduled major overhaul, inspection, performance restoration/shop
                        visit. Unless otherwise advised, each engines’ Life Limited Parts (LLPs) also have a minimal
                        amount of their certified cyclic lives remaining.
                    </p>
                    <p>Updated: April 2021</p>
                </IBox>
            </PageWrapper>
        );
    }
}

export default Glossary;
