import React, { Component, Suspense } from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "../assets/styles/application-styles.scss";
import "../assets/js/inspinia";
import "../entrypoints/axisConfig";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import configureStore from "../shared/utility";
import { combineReducers } from "redux";
import { flashMessages } from "../shared/flash_message/flash_messages_reducer";
import { aircraftVariants } from "./valuation/reducers/aircraftVariantsReducer";
import { engineSeries } from "./valuation/reducers/engineSeriesReducer";
import { engineModels } from "./valuation/reducers/engineModelsReducer";
import { aircraftBaselines } from "./valuation/reducers/aircraftBaselinesReducer";
import { aircraftModifiers } from "./valuation/reducers/aircraftModifiersReducer";
import { aircraftMarketValues } from "./valuation/reducers/aircraftMarketValuesReducer";
import { purchasePrices } from "./valuation/reducers/purchasePricesReducer";
import { vintage } from "./valuation/reducers/vintageReducer";
import { inflation } from "./valuation/reducers/inflationReducer";
import { mtow } from "./valuation/reducers/mtowReducer";
import { encumbered } from "./valuation/reducers/encumberedReducer";
import { transactionsFilter } from "./transactions/reducers/transactionsFilterReducer";
import { transactionsPurchasePrices } from "./transactions/reducers/transactionsPurchasePricesReducer";
import { aircraftFutureValues } from "./valuation/reducers/aircraftFutureValuesReducer";
import { INIT_STORE, userReducer } from "../shared/auth/reducers/user_reducer";
import NavBarNotLogged from "../shared/template/NavBarNotLogged";
import NavBar from "./NavBar";
import HomeApp from "./home/HomeApp";
import AuthApp from "../shared/auth/startup/AuthApp";
import NotFound from "../shared/template/NotFound";
import Footer from "../shared/template/Footer";
import TopNavBar from "../shared/template/TopNavBar";
import { createSearchReducer } from "../shared/reducers/searchReducerFactory";
import { reducer as formReducer } from "redux-form";
import { aircraftConfigurationReducer } from "../shared/portfolios/reducers/aircraftConfigurationReducer";
import CookieConsent from "react-cookie-consent";
import LoadingPage from "../shared/template/LoadingPage";
import ErrorBoundary from "../shared/ErrorBoundary";
import { aircraftPortfolios } from "./portfolios/reducers/aircraftPortfoliosReducer";
import Methodology from "./Methodology";
import Glossary from "./Glossary";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const ValuationApp = React.lazy(() => import("./valuation/startup/ValuationApp"));
const AboutApp = React.lazy(() => import("./about/startup/AboutApp"));
const PortfoliosApp = React.lazy(() => import("./portfolios/startup/PortfoliosApp"));
const TransactionsApp = React.lazy(() => import("./transactions/startup/TransactionsApp"));

const history = createBrowserHistory();

class App extends Component {
    constructor(props) {
        super(props);
        Sentry.init({
            environment: window.data.env,
            release: window.data.release_version,
            dsn: window.data.sentry_dsn,
            integrations: [
                new Integrations.BrowserTracing({
                    // Can also use reactRouterV4Instrumentation
                    routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
                }),
            ],
            tracesSampleRate: 0.2,
        });
    }

    createStore() {
        const store = configureStore(
            window.data,
            combineReducers({
                aircraftVariants,
                engineSeries,
                engineModels,
                aircraftBaselines,
                aircraftModifiers,
                aircraftMarketValues,
                purchasePrices,
                vintage,
                inflation,
                mtow,
                encumbered,
                transactionsFilter,
                transactionsPurchasePrices,
                aircraftFutureValues,
                flashMessages,
                userReducer,
                form: formReducer,
                aircraftPortfolios,
                aircraftConfigurationReducer,
                search: createSearchReducer([
                    { name: "aircraft_portfolio" },
                    { name: "portfolio_entry" },
                    { name: "portfolio_share" },
                    { name: "user" },
                ]),
            })
        );
        store.dispatch({ type: INIT_STORE, payload: { data: window.data } });
        return store;
    }

    render() {
        return (
            <Provider store={this.createStore()}>
                <Sentry.ErrorBoundary fallback={<ErrorBoundary wrapper={true} />}>
                    <Router history={history}>
                        <div id="wrapper">
                            <Switch>
                                <Route path="/login" component={NavBarNotLogged} />
                                <Route component={NavBar} />
                            </Switch>
                            <div id="page-wrapper" className="gray-bg">
                                <TopNavBar />
                                <Suspense fallback={<LoadingPage />}>
                                    <Switch>
                                        <Route exact path="/" component={HomeApp} />
                                        <Route path="/login" component={AuthApp} />
                                        <Route path="/methodology" component={Methodology} />
                                        <Route path="/glossary" component={Glossary} />
                                        <Route path="/valuations" component={ValuationApp} />
                                        <Route path="/transactions" component={TransactionsApp} />
                                        <Route path="/meet-the-experts" component={AboutApp} />
                                        <Route path="/portfolios" component={PortfoliosApp} />
                                        <Route component={NotFound} />
                                    </Switch>
                                </Suspense>
                                <Footer />
                            </div>
                        </div>
                    </Router>
                    {this.cookieConsent()}
                </Sentry.ErrorBoundary>
            </Provider>
        );
    }

    cookieConsent() {
        return (
            <CookieConsent
                cookieName="ishka-app-amplitude-consent"
                sameSite="lax"
                buttonClasses="btn btn-primary"
                style={{ zIndex: "2500" }}
            >
                By using this site, you consent to our use of cookies and data analysis according to our{" "}
                <a href="https://www.ishkaglobal.com/privacy" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                </a>
                .
            </CookieConsent>
        );
    }
}

export default Sentry.withProfiler(App);
